import React, { Fragment } from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import propTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';



export default function ButtonMenus({options, id}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleClick}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <Menu
         
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
            {options?.map((menuItem, idx) => 
                <Fragment key={idx}>
                    <MenuItem onClick={() => menuItem?.func(id)} disableRipple>
                        {menuItem?.icon}{' '}
                        {menuItem?.title}
                    </MenuItem>
                </Fragment>
            )}
        </Menu>
      </div>
    );
  }
  ButtonMenus.propTypes = {
    options: propTypes.array,
    id: propTypes.number
  }