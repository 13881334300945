import React from 'react'
// import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    // Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
   
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets

import { Link } from "react-router-dom";
import { useMutation } from 'react-query';
import UserProfile from 'service/UserProfile';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { LoadingButton } from '@mui/lab';


// import Google from 'assets/images/icons/social-google.svg';

// ============================|| FIREBASE - LOGIN ||============================ //


const validationSchema = yup.object({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
});

const ForgotPassword = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const { register, handleSubmit, formState: { errors },} = useForm({
        resolver: yupResolver(validationSchema),
      });
    
   
   

    const mutation = useMutation(UserProfile.forgotPassword);
    const submit = (data) =>{
        
        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", "Reset email sent successfully");
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
          );
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center" textAlign="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? 'h3' : 'h2'}
                        >
                            Forgot password?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" fontSize="16px" textAlign="center">
                            Enter your email address below and we&apos;ll send you a password reset mail.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

    
            <Grid item xs={12}>
                    <form onSubmit={handleSubmit(submit)}>
                        <FormControl fullWidth error={Boolean(errors?.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-forgot">Email Address</InputLabel>
                            <OutlinedInput
                                {...register("email")}
                                id="outlined-adornment-email-forgot"
                                type="email"
                                name="email"
                                label="Email Address / Username"
                                inputProps={{}}
                            />
                            {errors?.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors?.email?.message || 'eroor this'}
                                </FormHelperText>
                            )}
                        </FormControl>


                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                
                                <LoadingButton
                                    disableElevation
                                    disabled={mutation.isLoading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={mutation.isLoading}
                                >
                                    Send Mail
                                </LoadingButton>
                            </AnimateButton>
                        </Box>
                    </form>
                    </Grid>

                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Typography
                                                component={Link}
                                                to={'/auth/login'}
                                                variant="subtitle1"
                                                sx={{ textDecoration: 'none' }}
                                            >
                                                Already have an account?
                                            </Typography>
                                        </Grid>
                                    </Grid>
            
        </>
    );
};

export default ForgotPassword;
