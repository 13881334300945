// assets
import { IconCash, IconMail, IconShieldLock, IconSitemap, IconDashboard } from '@tabler/icons';


// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const client = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'My Dashboard',
            type: 'item',
            url: '/user/dashboard',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'network',
            title: 'My Network',
            type: 'item',
            url: '/user/network',
            icon: IconSitemap,
            breadcrumbs: false
        },
        {
            id: 'transactions',
            title: 'My Transactions',
            type: 'item',
            url: '/user/transactions',
            icon: IconCash,
            breadcrumbs: false
        },
        {
            id: 'profile',
            title: 'My Account',
            type: 'item',
            url: '/user/profile',
            icon: IconShieldLock,
            breadcrumbs: false
        },
        {
            id: 'messages',
            title: 'My Messages',
            type: 'item',
            url: '/user/messages',
            icon: IconMail,
            breadcrumbs: false
        }
    ]
};

export default client;
