import React, {lazy} from 'react'
// import { useRoutes } from 'react-router-dom';
import {
    Route,
    Routes,
  } from "react-router-dom";

// routes
// import MainRoutes from './MainRoutes';
// import AuthenticationRoutes from './AuthenticationRoutes';
// import config from 'config';

import Page404 from './Page404';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';

import Loadable from 'ui-component/Loadable';
import ForgotPassword from 'views/authentication/login/ForgotPassword';
import AuthLogin from 'views/authentication/login/AuthLogin';
import RequireAuth from 'service/RequireAuth';
import ResetPassword from 'views/authentication/login/ResetPassword';
import Admins from 'views/admins/Admins';
import Signup from 'views/authentication/signup';
import Customers from 'views/customers';
import Profile from 'views/profile';
import CustomerProfile from 'views/customers/CustomerProfile';
import Payments from 'views/payments';
import PayoutRequests from 'views/transactions/admin/PayoutRequests';
import Transactions from 'views/transactions/customer';
import Payouts from 'views/transactions/admin/Payouts';
import Messages from 'views/messages';

// login option 3 routing
// const AuthSigup = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const DashboardCustomer = Loadable(lazy(() => import('views/dashboard/customer')));
const DashboardAdmin = Loadable(lazy(() => import('views/dashboard/admin')));
const Network = Loadable(lazy(() => import('views/network/Network')));

// import Network from 'views/network/Network';





// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    
    return (
        <Routes>
            
            <Route element={<MinimalLayout />} >
                <Route exact path="/" element={<AuthLogin />} />
                <Route exact path="/auth/login" element={<AuthLogin />} />
                <Route exact path="/auth/signup" element={<Signup type='self'/>} />
                <Route exact path="/auth/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/auth/reset-password/:token" element={<ResetPassword />} />
                <Route path="/invite/:token" element={<Signup type='invite'/>} />
            </Route>
            {/* <Route path="/auth/signup" element={<AuthSigup />} /> */}
            <Route path="/admin" element={<RequireAuth><MainLayout /></RequireAuth> }>
                <Route exact path="/admin/dashboard" element={<DashboardAdmin />} />
                <Route exact path="/admin/admins" element={<Admins />} />
                <Route exact path="/admin/customers" element={<Customers />} />
                <Route exact path="/admin/profile" element={<Profile />} />
                <Route exact path="/admin/payments" element={<Payments />} />
                <Route exact path="/admin/customer/profile/:id" element={<CustomerProfile />} />
                <Route exact path="/admin/customer/network/:id" element={<Network />} />
                <Route exact path="/admin/payout-requests" element={<PayoutRequests />} />
                <Route exact path="/admin/payouts" element={<Payouts />} />
            </Route>
            <Route path="/user" element={<RequireAuth><MainLayout /></RequireAuth> }>
                <Route exact path="/user/dashboard" element={<DashboardCustomer />} />
                <Route exact path="/user/network" element={<Network />} />
                <Route exact path="/user/transactions" element={<Transactions />} />
                <Route exact path="/user/profile" element={<Profile />} />
                <Route exact path="/user/messages" element={<Messages />} />
            </Route>

            <Route path="*" element={<Page404 />} />
        </Routes>
    )
}
