import React, { Fragment } from 'react';

// material-ui
import { Button, Step, Stepper, StepLabel, Stack, Typography, Grid, Divider } from '@mui/material';

// project imports
import PersonalDetails from './PersonalDetails';
import SignupPackage from './SignupPackage';
import TermsAndConditions from './TermsAndConditions';
// import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import UserProfile from 'service/UserProfile';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import propTypes from 'prop-types'
// import { useDecrypt } from 'service/Encription';

// step options
const steps = ['Personal Details', 'Trustee & Beneficiaries', 'Terms and Conditions'];

const getStepContent = (step, handleNext, handleBack, setErrorIndex, signupData, setSignupData) => {
    switch (step) {
        case 0:
            return (
                <PersonalDetails
                    handleNext={handleNext}
                    setErrorIndex={setErrorIndex}
                    signupData={signupData}
                    setSignupData={setSignupData}
                />
            );
        case 1:
            return (
                <SignupPackage
                    handleNext={handleNext}
                    handleBack={handleBack}
                    setErrorIndex={setErrorIndex}
                    signupData={signupData}
                    setSignupData={setSignupData}
                />
            );
        case 2:
            return <TermsAndConditions signupData={signupData} setSignupData={setSignupData}/>;
        default:
            throw new Error('Unknown step');
    }
};

// ==============================|| FORMS WIZARD - BASIC ||============================== //

let payload = { title:'', 
                first_name:'', 
                last_name:'',
                other_names:'',
                gender:'',
                year: '',
                month: '',
                day: '',
                id_type: '',
                id_number: '',
                address: '',
                phone_number: '',
                email: '',
                sum_assured:'',
                trustee_name:'',
                trustee_date_of_birth:'1994-01-01',
                trustee_phone_number:'',
                trustee_relationship:'',
                beneficiaries:[{ beneficiary_name: "", beneficiary_date_of_birth: "1994-01-01", beneficiary_phone_number: "", beneficiary_relationship:"", beneficiary_percentage: "" }],
                accept_terms:false
            }
const Signup = ({type}) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [signupData, setSignupData] = React.useState(payload);
    const [errorIndex, setErrorIndex] = React.useState(null);
    const navigate = useNavigate()
    const params = useParams()

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        setErrorIndex(null);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    let refered_by = null
    if(type === 'invite'){
        const token = params?.token
        refered_by = token
    }
    


    const mutation = useMutation(UserProfile.signup);
    const submit = () => {
        let data = signupData
        data.date_of_birth = `${signupData?.year}-${signupData?.month}-${signupData?.day}`
        data.trustee = {trustee_name:data.trustee_name, trustee_date_of_birth:data.trustee_date_of_birth, 
                        trustee_phone_number:data.trustee_phone_number, trustee_relationship:data.trustee_relationship}

        if(refered_by) data.refered_by = refered_by

        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", 'Account created successfully');
                setActiveStep(activeStep + 1);
                setErrorIndex(null);
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
        );
        
    };

    return (
        <Fragment>
        <Grid item xs={12}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label, index) => {
                    const labelProps = {};

                    if (index === errorIndex) {
                        labelProps.optional = (
                            <Typography variant="caption" color="error">
                                Error
                            </Typography>
                        );

                        labelProps.error = true;
                    }

                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <>
                {activeStep === steps.length ? (
                    <>
                        <Typography variant="h5" gutterBottom>
                            Thank you for signing up.
                        </Typography>
                        <Typography variant="subtitle1">
                            We have emailed your account confirmation to complete signup and access your dashboard. Please check your spam if you have not received this email
                        </Typography>
                        <Stack direction="row" justifyContent="flex-end">
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setSignupData({});
                                        navigate('/auth/login')
                                    }}
                                    sx={{ my: 3, ml: 1 }}
                                >
                                    Done
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </>
                ) : (
                    <>
                        {getStepContent(
                            activeStep,
                            handleNext,
                            handleBack,
                            setErrorIndex,
                            signupData,
                            setSignupData,
                            
                        )}
                        {activeStep === steps.length - 1 && (
                            <>
                            <Stack direction="row" justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                                        Back
                                    </Button>
                                )}
                                <AnimateButton>
                                    <LoadingButton variant="contained" loading={mutation.isLoading} onClick={submit} sx={{ my: 3, ml: 1 }} disabled={!signupData?.accept_terms}>

                                        {activeStep === steps.length - 1 ? 'I accept' : 'Next'}
                                    </LoadingButton>
                                </AnimateButton>
                            </Stack>
                            </>
                        )}
                    </>
                )}
            </>
        </Grid>
        <Grid item xs={12}>
            <Divider />
        </Grid>
        <Grid item xs={12}>
            <Grid item container direction="column" alignItems="center" xs={12}>
                <Typography
                    component={Link}
                    to={'/auth/login'}
                    variant="subtitle1"
                    sx={{ textDecoration: 'none' }}
                >
                    Already have an account?
                </Typography>
            </Grid>
        </Grid>
        </Fragment>
    );
};

export default Signup;

Signup.propTypes = {
    type: propTypes.string
}
