import React from 'react'
import { useState } from 'react';
// import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    // Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from 'react-query';
import UserProfile from 'service/UserProfile';
import { handleErrorMessage, messageAlert, storeLocalStorage } from 'utils/helpers/functions';
import { useDispatch } from 'react-redux';
import { addUserInfo } from 'store/user/userReducer';
import { LoadingButton } from '@mui/lab';


// import Google from 'assets/images/icons/social-google.svg';

// ============================|| FIREBASE - LOGIN ||============================ //


const validationSchema = yup.object({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
    password: yup.string().max(255).required('Password is required')
});

const AuthLogin = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { register, handleSubmit, formState: { errors },} = useForm({
        resolver: yupResolver(validationSchema),
      });
    
   
    const [checked, setChecked] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const mutation = useMutation(UserProfile.login);
    const submitLogin = (data) =>{
        
        mutation.mutate(data,
            {
              onSuccess: (response) => {
                const accessToken = response.headers?.["set-auth-token"];
                storeLocalStorage("accessToken", accessToken);
                storeLocalStorage("userData", JSON.stringify(response.data));
                dispatch(addUserInfo(response.data));
                const user = response.data
                const userNav = user?.role === 'admin' ? 'admin':'user';
                navigate(`/${userNav}/dashboard`)
                
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
          );
    }

    return (
        <>  
             <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Hi, Welcome Back
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        Enter your credentials to continue
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Grid container direction="column" justifyContent="center" spacing={2}>
                
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="subtitle1">Sign in with Email address</Typography>

                    </Box>
                </Grid>
            </Grid>

    
                
                    <form onSubmit={handleSubmit(submitLogin)}>
                        <FormControl fullWidth error={Boolean(errors?.email)} sx={{ ...theme.typography.customInput }}>
                            <InputLabel htmlFor="outlined-adornment-email-login">Email Address</InputLabel>
                            <OutlinedInput
                                {...register("email")}
                                id="outlined-adornment-email-login"
                                type="email"
                                name="email"
                                label="Email Address"
                                inputProps={{}}
                            />
                            {errors?.email && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors?.email?.message || 'eroor this'}
                                </FormHelperText>
                            )}
                        </FormControl>

                        <FormControl
                            fullWidth
                            error={Boolean(errors?.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-login">Password </InputLabel>
                            <OutlinedInput
                                {...register("password")}
                                id="outlined-adornment-password-login"
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                inputProps={{}}
                            />
                            {errors?.password && (
                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                    {errors?.password?.message || 'error this'}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        name="checked"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Typography onClick={()=>navigate('/auth/forgot-password')} variant="subtitle1" color="secondary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                                Forgot Password?
                            </Typography>
                        </Stack>
                            
                    
                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                
                                <LoadingButton
                                    disableElevation
                                    disabled={mutation.isLoading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={mutation.isLoading}
                                >
                                    Sign in
                                </LoadingButton>
                            </AnimateButton>
                        </Box>
                    </form>
                                        
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Link to="/auth/signup" style={{ textDecoration: 'none' }}>
                                                <Typography
                                                    variant="subtitle1"
                                                    >
                                                    Don&apos;t have an account?
                                                </Typography>
                                            </Link>
                                        </Grid>
                                    </Grid>
           
            
        </>
    );
};

export default AuthLogin;
