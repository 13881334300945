import apiConfig from "./apiConfig";

const login = (data) => {
    const url = `auth/account/login`;
    return apiConfig.postRequestUnAuth(url, data);
  };

const logout = () => {
    localStorage.clear()
    const url = `auth/account/logout`;
    return apiConfig.postRequest(url);
};

const signup = (data) => {
    const url = `auth/account/signup`;
    return apiConfig.postRequestUnAuth(url, data);
};
  

const getSelf = () => {
  const url = `users/self?id`;
  return apiConfig.getRequest(url);
};

const getUser = (id) => {
    const url = `users/${id}`;
    return apiConfig.getRequest(url);
};

const forgotPassword = (data) => {
    const url = `auth/account/forgot_password`;
    return apiConfig.postRequestUnAuth(url, data);
};

const resetPassword = (data) => {
    const url = `auth/account/reset_password`;
    return apiConfig.postRequestUnAuth(url, data);
};
  
const refreshToken = () => {
    const url = `auth/account/refresh_token`;
    return apiConfig.getRequest(url);
};

const updateSelf = (data) => {
    const url = `users/update`;
    return apiConfig.patchRequest(url, data);
};

const changePassword = (data) => {
    const url = `auth/account/update_password`;
    return apiConfig.postRequest(url, data);
};

export default {
    login,
    signup,
    logout,
    getSelf,
    getUser,
    forgotPassword,
    refreshToken,
    resetPassword,
    updateSelf,
    changePassword
};
