// material-ui
import React from 'react'
import { Link, Typography, Stack } from '@mui/material';
import Logo from 'ui-component/Logo';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" component={Link} href="https://speedwave.amsghana.xyz/" target="_blank" underline="hover">
            &copy; {new Date().getFullYear()} Speedwave
        </Typography>
        <Typography variant="subtitle2" component={Link} href="https://speedwave.amsghana.xyz/" target="_blank" underline="hover">
            <Logo width='90'/>
        </Typography>
    </Stack>
);

export default AuthFooter;
