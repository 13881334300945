import apiConfig from "./apiConfig";


const getUserAccounts = () => {
    const url = `users/`;
    return apiConfig.getRequest(url);
};

const getUserCustomers = () => {
    const url = `users/customers`;
    return apiConfig.getRequest(url);
};



const getUser = (id) => {
    const url = `users/${id}`;
    return apiConfig.getRequest(url);
};

const getCustomer = (id) => {
    const url = `users/${id}/customer?`;
    return apiConfig.getRequest(url);
};


const createUser = (data) => {
    const url = `users/create_account`;
    return apiConfig.postRequest(url, data);
};

const approveCustomer = (data) => {
    const url = `users/${data?.id}/approve_customer`;
    return apiConfig.postRequest(url, data);
};

const setMomoNumber = (data) =>{
    const url = `users/set_momo_number`;
    return apiConfig.postRequest(url, data);
}

const setBankAccount = (data) =>{
    const url = `users/set_bank_account`;
    return apiConfig.postRequest(url, data);
}

const getPayments = () => {
    const url = `payments/payments`;
    return apiConfig.getRequest(url);
};
const requestPayout = (data) =>{
    const url = `payments/request_payout`;
    return apiConfig.postRequest(url, data);
}

const payoutRequests = () => {
    const url = `payments/payout_requests`;
    return apiConfig.getRequest(url);
};

const transactions = () => {
    const url = `payments/transactions`;
    return apiConfig.getRequest(url);
};

const payout = (data) => {
    const url = `payments/payout`;
    return apiConfig.postRequest(url, data);
};

const payouts = () => {
    const url = `payments/payouts`;
    return apiConfig.getRequest(url);
};

const getDashboardMetrics = () => {
    const url = `users/dashboard_metrics`;
    return apiConfig.getRequest(url);
};

const getNotifications = () => {
    const url = `users/notifications`;
    return apiConfig.getRequest(url);
};

const readNotification = (id) => {
    
    const url = `users/${id}/read_notification`;
    return apiConfig.getRequest(url);
};

const deleteNotification = (data) => {
    const id = data?.id
    const url = `users/${id}/delete_notification`;
    return apiConfig.deleteRequest(url);
};

export default {
    getUser,
    getUserAccounts,
    createUser,
    getUserCustomers,
    getCustomer,
    approveCustomer,
    getPayments,
    setMomoNumber,
    setBankAccount,
    requestPayout,
    payoutRequests,
    transactions,
    payouts,
    payout,
    getDashboardMetrics,
    getNotifications,
    readNotification,
    deleteNotification
};
