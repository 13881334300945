import React, { Fragment, useState } from 'react';

import PropTypes from 'prop-types';

// material-ui
import { Button, FormLabel, Grid, Stack, TextField, Typography } from '@mui/material';

// project imports
import { Close } from '@mui/icons-material';


// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { messageAlert } from 'utils/helpers/functions';

const validationSchema = yup.object({
    trustee_name: yup.string().required('Rrequired'),
    trustee_date_of_birth: yup.string().required('Required'),
    trustee_phone_number: yup.string().max('13').min('13').required('Phone number is required'),
    trustee_relationship: yup.string().required('Required')
});

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

export default function SignupPackage({ signupData, setSignupData, handleNext, handleBack, setErrorIndex}) {

    console.log('signupData',signupData)

    const formik = useFormik({
        initialValues: signupData,
        validationSchema,
        onSubmit: (values) => {
            let figures = []

            inputField?.map((item) => figures.push(parseFloat(item?.beneficiary_percentage)))
            const percantage = figures.reduce((a, b) => a + b, 0)

            console.log('percantage',percantage)

            if(percantage !== 100) return messageAlert("error", 'Percentage must equal 100%');

            signupData.beneficiaries = inputField

            console.log('on next signupData',signupData)
            console.log('on next values',values)
            delete values.beneficiaries
            setSignupData({...signupData, ...values})
            handleNext();
        }
    });


    const [inputField, setInputField] = useState(signupData?.beneficiaries || []);
    
      const handleAddField = () => {
        let values = [...inputField];
        values.push({ beneficiary_name: "", beneficiary_date_of_birth: "1994-01-01", beneficiary_phone_number: "", beneficiary_relationship:"", beneficiary_percentage: "" });
        setInputField(values);
      };
    
      const handleRemoveField = (index) => {
        const values = [...inputField];
        values.splice(index, 1);
        setInputField(values);
      };
      const handleInputChange = (index, event) => {
        let values = [...inputField];
        if (event.target?.name) {
            values[index][event.target.name] = event.target.value;
        }
        if(!event.target && event.includes('+')){
            values[index]['beneficiary_phone_number'] = event;
        }
        setInputField(values);
      };
      

    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                        <FormLabel id="trustee">Trustee</FormLabel>
                        <Grid container spacing={1} sx={{mt: 1}}>
                            <Grid item xs={12} sm={4}>
                                <TextField 
                                    id="trustee_name" 
                                    name="trustee_name"
                                    label="Full name" 
                                    value={formik.values.trustee_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.trustee_name && Boolean(formik.errors.trustee_name)}
                                    helperText={formik.touched.trustee_name && formik.errors.trustee_name}
                                    fullWidth 
                                    />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="trustee_date_of_birth"
                                    label="Birth of birth"
                                    name="trustee_date_of_birth"
                                    type="date"
                                    value={formik.values.trustee_date_of_birth}
                                    onChange={formik.handleChange}
                                    error={formik.touched.trustee_date_of_birth && Boolean(formik.errors.trustee_date_of_birth)}
                                    helperText={formik.touched.trustee_date_of_birth && formik.errors.trustee_date_of_birth}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <MuiPhoneNumber
                                    margin="small"
                                    defaultCountry={'gh'}
                                    variant="outlined"
                                    label="Phone number"
                                    disableAreaCodes
                                    name="trustee_phone_number"
                                    countryCodeEditable={false}
                                    value={formik.values.trustee_phone_number}
                                    onChange={e => formik.setFieldValue("trustee_phone_number", e)}
                                    error={formik.touched.trustee_phone_number && Boolean(formik.errors.trustee_phone_number)}
                                    helperText={formik.touched.trustee_phone_number && formik.errors.trustee_phone_number}
                                    />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField 
                                    id="trustee_relationship" 
                                    name="trustee_relationship"
                                    label="Relationship" 
                                    value={formik.values.trustee_relationship}
                                    onChange={formik.handleChange}
                                    error={formik.touched.trustee_relationship && Boolean(formik.errors.trustee_relationship)}
                                    helperText={formik.touched.trustee_relationship && formik.errors.trustee_relationship}
                                    fullWidth 
                                    sx={{mt: 1}}
                                    />
                            </Grid>

                        </Grid>
                    </Grid>

                    
                    <Grid item xs={12} sm={12}>
                        <FormLabel id="trustee">
                            Beneficiary
                        </FormLabel>

                            {inputField?.length < 3 &&
                                <Button variant="outlined" size="small" onClick={() => handleAddField()} sx={{float: "right"}}>
                                    Add Beneficiary
                                </Button>
                            }


                        
                        {inputField.map((item, index) => (
                            <Fragment key={index}>
                                {index !== 0 &&
                                    <Close sx={{width:'1rem', float: "right"}} className='small' onClick={() => handleRemoveField(index)}/>
                                }
                                
                                <Grid container spacing={1} sx={{mt: 1}}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            id="beneficiary_name" 
                                            name="beneficiary_name"
                                            label="Full name"
                                            defaultValue={item?.beneficiary_name}
                                            onChange={e => handleInputChange(index, e)}
                                            fullWidth 
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            id="beneficiary_date_of_birth" 
                                            name="beneficiary_date_of_birth"
                                            label="Date of birth"
                                            type="date"
                                            defaultValue={item?.beneficiary_date_of_birth}
                                            onChange={e => handleInputChange(index, e)}
                                            fullWidth 
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <MuiPhoneNumber
                                            margin="small"
                                            defaultCountry={'gh'}
                                            variant="outlined"
                                            label="Phone number"
                                            value={item?.beneficiary_phone_number}
                                            disableAreaCodes
                                            name="beneficiary_phone_number"
                                            countryCodeEditable={false}
                                            onChange={e => handleInputChange(index, e)}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            id="beneficiary_relationship" 
                                            name="beneficiary_relationship"
                                            label="Relationship" 
                                            defaultValue={item?.beneficiary_relationship}
                                            onChange={e => handleInputChange(index, e)}
                                            fullWidth 
                                            sx={{mt: 1}}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField 
                                            id="beneficiary_percentage" 
                                            name="beneficiary_percentage"
                                            label="Percentage" 
                                            defaultValue={item?.beneficiary_percentage}
                                            type={'number'}
                                            onChange={e => handleInputChange(index, e)}
                                            fullWidth 
                                            sx={{mt: 1}}
                                            />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        ))}  
                    </Grid> 
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="space-between">
                            <Button onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                                Back
                            </Button>
                            <AnimateButton>
                                <Button variant="contained" type="submit" sx={{ my: 3, ml: 1 }} onClick={() => setErrorIndex(1)}>
                                    Next
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

SignupPackage.propTypes = {
    signupData: PropTypes.object,
    setSignupData: PropTypes.func,
    // paymentData: PropTypes.object,
    // setPaymentData: PropTypes.func,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    setErrorIndex: PropTypes.func
};
