import React, { useEffect, useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Grid, useMediaQuery } from '@mui/material';

// project imports
import MailDetails from './MailDetails';
import MailList from './MailList';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';
import { useDispatch } from 'react-redux';
import { filterMails, } from 'store/slices/mail';
import UserAccounts from 'service/UserAccounts';
import { useQuery } from 'react-query';
import MainCard from 'ui-component/cards/MainCard';

// drawer content element
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: 'calc(100% - 320px)',
    flexGrow: 1,
    paddingLeft: open ? theme.spacing(3) : 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
    }),
    marginLeft: `-${drawerWidth}px`,
    [theme.breakpoints.down('xl')]: {
        paddingLeft: 0,
        marginLeft: 0
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0
    })
}));

// ==============================|| MAIL MAIN PAGE ||============================== //

const Messages = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));

    const dispatch = useDispatch();

    const [emailDetails, setEmailDetailsValue] = useState(false);
    const [selectedMail, setSelectedMail] = useState(null);
    const handleUserChange = async (data) => {
        
        setSelectedMail(data);
        setEmailDetailsValue((prev) => !prev);
    };

    const [openMailSidebar, setOpenMailSidebar] = useState(true);
    

    useEffect(() => {
        if (matchDownSM) {
            setOpenMailSidebar(false);
        } else {
            setOpenMailSidebar(true);
        }
    }, [matchDownSM]);

    // const [data, setData] = useState([]);

    const {data: responseData,  refetch} = useQuery("notifications", UserAccounts.getNotifications);
    const data = responseData?.data?.results;



    

    const [filter, setFilter] = useState('all');
    const handleFilter = async (string) => {
        setEmailDetailsValue(false);
        setFilter(string);
        await dispatch(filterMails(string));
    };

   

    // search email using name
    const [search, setSearch] = useState('');
    const handleSearch = (event) => {
        const newString = event.target.value;
        setSearch(newString);

        if (newString) {
            const newRows = data.filter((row) => {
                let matches = true;

                const properties = ['name'];
                let containsQuery = false;

                properties.forEach((property) => {
                    if (row.profile[property].toString().toLowerCase().includes(newString.toString().toLowerCase())) {
                        containsQuery = true;
                    }
                });

                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
            console.log(newRows);
        } else {
            handleFilter(filter);
        }
    };

    return (
        <MainCard
    content={false}
    title="Messages" 
    >
        <Box sx={{ display: 'flex' }}>
            
            <Main theme={theme} open={openMailSidebar}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        {/* mail details & list */}
                        {emailDetails ? (
                            <MailDetails
                                data={selectedMail}
                                handleUserDetails={(e, d) => handleUserChange(d)}
                                
                            />
                        ) : (
                            <MailList
                                handleUserDetails={(e, d) => handleUserChange(d)}
                                data={data}
                                search={search}
                                handleSearch={handleSearch}
                                refetch={refetch}
                            />
                        )}
                    </Grid>
                </Grid>
            </Main>
        </Box>
        </MainCard>
    );
};

export default Messages;
