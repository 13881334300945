// material-ui
import React from 'react'
// import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { Badge, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import { useQuery } from 'react-query';
import UserAccounts from 'service/UserAccounts';
import { formatDate } from 'utils/helpers/functions';


function Transactions (){
    
    

    const columns = [
        { id: 'created', label: 'Date', minWidth: 100, format: (value) => `${formatDate(value)}`},
        { id: 'amount', label: 'Amount (GHS)', minWidth: 100 },
        { id: 'paid', label: 'Paid', minWidth: 100, format: (value) => <Badge color={`${value===true?'primary':'warning'}`} badgeContent={value===true?'Yes':'No'}/>},
        { id: 'status', label: 'Status', minWidth: 100, format: (value) => <Badge color={`${value==='approved'?'primary':'warning'}`} badgeContent={value}/>},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const {data: responseData,} = useQuery("transactions", UserAccounts.transactions);
    const payments = responseData?.data?.results;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target?.value);
        setPage(0);
    };

  

    


    
    return(
    
    <MainCard
    content={false}
    title="Payout Requests" 
    >

    {/* table */}
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {payments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                            const value = column.id === 'data' ? row : row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>


    {/* table pagination */}
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={payments?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />


</MainCard>
)}

export default Transactions;
