// assets
import { AddToHomeScreen, SendToMobile } from '@mui/icons-material';
import { IconCash, IconDashboard, IconDeviceAnalytics, IconSettings, IconUser, IconUsers } from '@tabler/icons';


// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const admin = {
    id: 'dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/admin/dashboard',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'admins',
            title: 'System Admins',
            type: 'item',
            url: '/admin/admins',
            icon: IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'customers',
            title: 'Customers',
            type: 'item',
            url: '/admin/customers',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: '/admin/payments',
            icon: IconCash,
            breadcrumbs: false
        },
        {
            id: 'payout-requests',
            title: 'Payout Requests',
            type: 'item',
            url: '/admin/payout-requests',
            icon: SendToMobile,
            breadcrumbs: false
        },
        {
            id: 'payouts',
            title: 'Payouts',
            type: 'item',
            url: '/admin/payouts',
            icon: AddToHomeScreen,
            breadcrumbs: false
        },
        {
            id: 'profile',
            title: 'Profile Settings',
            type: 'item',
            url: '/admin/profile',
            icon: IconUser,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: 'System Settings',
            type: 'item',
            url: '#',
            icon: IconSettings,
            breadcrumbs: false
        },
       
    ]
};

export default admin;
