// material-ui
import React, { useState } from 'react'
// import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import { useQuery } from 'react-query';
import UserAccounts from 'service/UserAccounts';
import { formatDate } from 'utils/helpers/functions';
import PaymentDetailsModal from './modals/Details';


function Payments (){
    const [payment, setPayment] = useState({})
    
    

    const columns = [
        { id: 'created', label: 'Date', minWidth: 100, format: (value) => `${formatDate(value)}`},
        { id: 'customer', label: 'Customer', minWidth: 100, format: (value) => `${value.first_name} ${value.last_name}`},
        { id: 'amount', label: 'Amount', minWidth: 100 },
        { id: 'duration', label: 'Duration', minWidth: 100 },
        { id: 'duration_range', label: 'Range', minWidth: 100},
        { id: 'category', label: 'Category', minWidth: 100},
        { id: 'data', label: '', minWidth: 100, format: (value) => <Button variant="outlined" size="small" onClick={()=>handleOpen(value)}>Details</Button>},
        // { id: 'approved_by', label: 'Approved By', minWidth: 100, format: (value) => `${value.first_name} ${value.last_name}`},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);


    const {data: responseData} = useQuery("payments", UserAccounts.getPayments);
    const payments = responseData?.data?.results;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target?.value);
        setPage(0);
    };

    const handleClose = () =>{
        setOpen(false)
    }

    const handleOpen = (value) =>{
        setOpen(true)
        setPayment(value)

    }


    
    return(
    
    <MainCard
    content={false}
    title="Payments" 
    >

    {/* table */}
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {payments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                            const value = column.id === 'data' ? row : row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    <PaymentDetailsModal handleClose={handleClose}  open={open} payment={payment}/>


    {/* table pagination */}
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={payments?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />


</MainCard>
)}

export default Payments;
