import React from 'react'
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const queryClient = new QueryClient();

    localStorage.setItem('currency', 'GHS')
    localStorage.setItem('country_code', 'gh')

    // useEffect(()=>{
    //     axios.get('https://ipapi.co/json/').then((response) => {
    //         localStorage.setItem('currency', response?.data?.currency)
    //         localStorage.setItem('country_code', response?.data?.country_code?.toLowerCase())
    //     }).catch(() => {
    //         return
    //     });
    // })

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <QueryClientProvider client={queryClient}>
                        <ToastContainer />
                        <Routes />
                    </QueryClientProvider>
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
