// material-ui
import React from 'react'
// import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useTheme } from '@mui/material/styles';
import { Badge, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, TextField, Grid, FormHelperText, Select, MenuItem, InputLabel} from '@mui/material';
import { useMutation, useQuery } from 'react-query';
import UserAccounts from 'service/UserAccounts';
import { getLocalStorage, handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import MuiPhoneNumber from "material-ui-phone-number";
import { LoadingButton } from '@mui/lab';

// project imports

const columns = [
    { id: 'first_name', label: 'First Name', minWidth: 100},
    { id: 'last_name', label: 'Last Name', minWidth: 100},
    { id: 'email', label: 'Email', minWidth: 100 },
    { id: 'phone_number', label: 'Phone Number', minWidth: 100 },
    { id: 'role', label: 'Role', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100, align: 'center', format: (value) => <Badge color={`${value==='active'?'primary':'warning'}`} badgeContent={value}/>}
];

const validationSchema = yup.object({
    email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
    first_name: yup.string().max(255).required('First name is required'),
    last_name: yup.string().max(255).required('Last name is required'),
    phone_number: yup.string().max(255).required('Phone number is required'),
    role: yup.string().max(255).required('Role is required')
});

function Admins (){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const {data: responseData, refetch} = useQuery("admins", UserAccounts.getUserAccounts);
    const users = responseData?.data?.results;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target?.value);
        setPage(0);
    };


    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const { register, handleSubmit, control, formState: { errors },} = useForm({
        resolver: yupResolver(validationSchema),
      });

    const mutation = useMutation(UserAccounts.createUser);
    const submit= (data) =>{
        
        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", "Admin added successfully");
                document.getElementById("add-user-form").reset();
                refetch()
                
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
          );
    }


    return(
    
    <MainCard
    content={false}
    title="System Admins"
    secondary={<Button variant="outlined" onClick={handleClickOpen}>
    Add Admin
</Button>}
    
>

    {/* table */}
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                            const value = row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>

    {/* table pagination */}
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />


<Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <>
                        <form onSubmit={handleSubmit(submit)} id='add-user-form'>
                            <DialogTitle id="alert-dialog-title">New Admin</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography variant="body2" component="span">
                                        Add new system adminstrator
                                    </Typography>
                                    
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                {...register('first_name')}
                                                fullWidth
                                                label="First Name"
                                                margin="normal"
                                                name="first_name"
                                                type="text"                                   
                                            />
                                            {errors?.first_name && (
                                                <FormHelperText error id="standard-weight-helper-text-password-login">
                                                    {errors?.first_name?.message || 'error this'}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                {...register('last_name')}
                                                fullWidth
                                                label="Last Name"
                                                margin="normal"
                                                name="last_name"
                                                type="text"
                                            />
                                            {errors?.last_name && (
                                                <FormHelperText error>
                                                    {errors?.last_name?.message || 'error this'}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                {...register('email')}
                                                fullWidth
                                                label="Email"
                                                margin="normal"
                                                name="email"
                                                type="email"                                   
                                            />
                                            {errors?.email && (
                                                <FormHelperText error>
                                                    {errors?.email?.message || 'error this'}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                        <Controller
                                            name="phone_number"
                                            control={control}
                                            rules={{required:true}}
                                            render={({ field }) =>
                                            <MuiPhoneNumber
                                                {...field}
                                                margin="normal"
                                                defaultCountry={getLocalStorage('country_code')}
                                                variant="outlined"
                                                label="Phone Number"
                                                disableAreaCodes
                                                countryCodeEditable={false}
                                                />
                                            }/>
                                            {errors?.phone_number && (
                                                <FormHelperText error>
                                                    {errors?.phone_number?.message || 'error this'}
                                                </FormHelperText>
                                            )}
                                        
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <InputLabel id="role-label">Role</InputLabel>
                                            <Select
                                                {...register('role')}
                                                fullWidth
                                                id="role"
                                                label="Age"
                                                labelId="role-label"
                                            >   
                                                <MenuItem value={'admin'}>Admin</MenuItem>
                                                <MenuItem value={'staff'}>Staff</MenuItem>
                                            </Select>
                                            {errors?.role && (
                                                <FormHelperText error>
                                                    {errors?.role?.message || 'error this'}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ pr: 2.5 }}>
                                <Button
                                    sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                    onClick={handleClose}
                                    color="secondary"
                                >
                                    Close
                                </Button>
                                <LoadingButton loading={mutation.isLoading} variant="contained" size="small" type="submit">
                                    Add
                                </LoadingButton>
                                
                            </DialogActions> 
                        </form>
                    </>
                   
                )}
            </Dialog>
</MainCard>
)}

export default Admins;
