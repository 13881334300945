import { format, formatDistance } from "date-fns";
import { toast } from "react-toastify";


export function handleErrorMessage(response) {
    try {
      switch (response?.status) {
        case 400:
          var errorMessage = Object.values(response?.data)[0][0];
          return (
            errorMessage || Object.values(Object.values(response?.data)[0])[0][0]
          );
        case 401:
          localStorage.clear();
          window.location.replace("/");
          return response?.data?.detail;
        case 404:
          return response?.data?.detail;
        case 422:
          return response?.data?.message || 'Invaild Request';
        default:
          return "Error Occured";
      }
    } catch (e) {
      return "Error Occured";
    }
  }

export const messageAlert = (messageType, msg) => {
    messageType === "success"
      ? toast.success(msg)
      : toast.error(msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
};

  export function storeLocalStorage(title, data) {
    window.localStorage.setItem(title, data);
    return;
  }
  
  export function getLocalStorage(title) {
    return window.localStorage.getItem(title) || null;
  }

  export function delay(secs, func){
    setTimeout(function () {
        func()
    }, secs);
  }


export const daysRange = (arr) => {
    const [n, m] = arr;
    return [...Array(m - n + 1).keys()].map(i => {
      let num = i + n
      let day = num.toString().length > 1 ? num : '0'+ num
      return {label:day, value:day}
    });
}

export function getGreetings(){
  let today = new Date()
  let curHr = today.getHours()

  if (curHr < 12) {
    return "Good Morning";
  } else if (curHr < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }

}

export function formatDateCleaan(date) {
  return date && new Date(date).toISOString().split('T')[0];
}

export function cleanDate(data) {
  return formatDistance(new Date(data), new Date()) + ' ago';
}

export function formatDatetime(data) {
  return format(new Date(data), 'dd MMMM, yyyy hh:mm a');
  
}
export function formatDate(data) {
  return format(new Date(data), 'dd MMMM, yyyy');
  
}
export function formatTime(data) {
  return format(new Date("2020-01-01 " + data), 'hh:mm a');
  
}