import { handleErrorMessage } from "utils/helpers/functions";
import { useQuery } from "react-query";
import UserProfile from "service/UserProfile";
import { storeLocalStorage } from "../helpers/functions";
import { addUserInfo } from "store/user/userReducer";
import { useDispatch } from "react-redux";

export default function useGetUserData() {
    const { error, data, isLoading } = useQuery("user-data", UserProfile.getUser);
    const userData = data?.data;
    handleErrorMessage(error?.response)
    return { userData, error, isLoading };
}

export async function useGetSelf() {
    const dispatch = useDispatch();
    try {
        await UserProfile.getSelf().then((response) =>{
        dispatch(addUserInfo(response.data));

    })}
    catch (error) {
        handleErrorMessage(error.response)
    }
}

export function useUserLogin() {
    const { error, data, isLoading } = useQuery("user-login", UserProfile.login);
    const userData = data?.data;
    handleErrorMessage(error?.response)
    return { userData, error, isLoading };
}

export async function useRefreshToken() {
    try {
        let response = await UserProfile.refreshToken()
        const asscessToken = response?.headers?.["set-auth-token"]
        if(asscessToken){
            storeLocalStorage('accessToken', asscessToken)
        }
    }catch (error) {
        handleErrorMessage(error.response)
    }
}

