import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { useMutation } from 'react-query';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import UserAccounts from 'service/UserAccounts';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

export default function ApproveModal({handleClose, open, payment, refetch}) {
    const {  handleSubmit } = useForm({});
    const theme = useTheme();
    const mutation = useMutation(UserAccounts.payout)

    function submit(data){
        data.id = payment?.id
        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", 'Payout initiated successfully');
                refetch()
                handleClose()
                
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
        );
    }
    
    return (
        <>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <>
                        <DialogTitle id="alert-dialog-title" sx={{ textTransform: 'capitalize' }}>Approve payout request</DialogTitle>
                        <form onSubmit={handleSubmit(submit)}>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography variant="body2" component="span">
                                         Are you sure you want to approve amount of <br/>
                                         <strong>GHS {payment?.amount}</strong>
                                    </Typography>
                                    
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ pr: 2.5 }}>
                                <Button
                                    sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                    onClick={handleClose}
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                        autoFocus
                                        disableElevation
                                        disabled={mutation.isLoading}
                                        size="small"
                                        type="sumbit"
                                        variant="contained"
                                        loading={mutation.isLoading}
                                        
                                    >
                                        Approve
                                    </LoadingButton>
                            </DialogActions>
                        </form>
                    </>
                )}
            </Dialog>
        </>
    );
}
ApproveModal.propTypes = {
    handleClose: propTypes.func,
    open: propTypes.bool,
    payment: propTypes.object,
    refetch: propTypes.func
}