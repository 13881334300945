import axios from "axios";
import { getLocalStorage } from "utils/helpers/functions";
import constants from "utils/constants";

const BASE_API = constants.BASE_API


const postRequest = (url, data) => {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLocalStorage("accessToken")}`,
    },
  };

  return axios.post(`${BASE_API}/${url}`, data, config);
};

const patchRequest = (url,data) => 
{
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLocalStorage("accessToken")}`,
    },
  };

  return axios.patch(`${BASE_API}/${url}`, data, config);

}

const getRequest = (url, params) => {
  return axios({
    method: "get",
    params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLocalStorage("accessToken")}`,
    },
    url: `${BASE_API}/${url}`,
  });
};

const deleteRequest = (url, params) => {
  return axios({
    method: "delete",
    params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLocalStorage("accessToken")}`,
    },
    url: `${BASE_API}/${url}`,
  });
};

const postRequestUnAuth = (url, data) => {
  let config = {
    headers: {
      "Content-Type": "application/json"
    },
  };

  return axios.post(`${BASE_API}/${url}`, data, config);
};


export default {
  postRequest,
  getRequest,
  patchRequest,
  postRequestUnAuth,
  deleteRequest
};
