import React from 'react'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Container, CssBaseline, Typography, Grid, Button } from '@mui/material';
import { IconHome } from '@tabler/icons';
import { useNavigate } from 'react-router-dom'



const theme = createTheme();

export default function Page404() {

  const navigate = useNavigate()

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            
            <Grid
                container
                spacing={0}
                align="center"
                justify="center"
                direction="column"
            >
                <Typography component="h6" variant="h1">
                404
                </Typography>

                <Typography component="h6" variant="h3">
                    Page not found 
                </Typography>
                <Typography component="h6" variant="h3">
                    <Button variant="outlined" color="error" startIcon={<IconHome />} onClick={()=> navigate('/user/dashboard')}>Go Home</Button>
                </Typography>
                </Grid>
             
          </Box>
          
        </Container>
      </ThemeProvider>
        
    )
}