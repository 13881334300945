import React from 'react';

// material-ui
import { Collapse, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField } from '@mui/material';

// third-party

// project imports
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets

import { useFormik } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import { MobileScreenShare, CreditCard} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { useMutation } from 'react-query';
import UserAccounts from 'service/UserAccounts';
import { addUserInfo } from 'store/user/userReducer';
import { LoadingButton } from '@mui/lab';



const validationSchema = yup.object({
    momo_phone_number: yup.string().max('13').min('13').required('Phone number is required'),

});

const validationSchemaBank = yup.object({
    account_name: yup.string().required('Phone number is required'),
    bank_name: yup.string().required('Phone number is required'),
    account_number: yup.string().required('Phone number is required'),
    bank_branch: yup.string().required('Phone number is required'),
});




// ==============================|| PROFILE 2 - PAYMENT ||============================== //

const Payment = () => {

    const user = useSelector((state) => state.user.userInfo);
    const dispatch = useDispatch()
   
    const [value1, setValue1] = React.useState('momo');
    const handleChange1 = (event) => {
        setValue1(event.target.value);
    };

    const mutation = useMutation(UserAccounts.setMomoNumber);
    const formik = useFormik({
        initialValues: {momo_phone_number:user?.momo_phone_number},
        validationSchema,
        onSubmit: (data) => {

            mutation.mutate(data,
                {
                  onSuccess: (response) => {
                    messageAlert("success", 'Mobile money number saved successfully');
                    dispatch(addUserInfo(response.data));
                  },
                  onError: (error) => {
                    var errorMessage = handleErrorMessage(error?.response);
                    messageAlert("error", errorMessage);
                  },
                }
            );
        }
    });

    const mutationBank = useMutation(UserAccounts.setBankAccount);
    const formikBankAccount = useFormik({
        initialValues: {
            account_name: user?.bank_account?.account_name,
            bank_name: user?.bank_account?.bank_name,
            account_number: user?.bank_account?.account_number,
            bank_branch: user?.bank_account?.bank_branch,
        
        },
        validationSchemaBank,
        onSubmit: (data) => {

            mutationBank.mutate(data,
                {
                  onSuccess: (response) => {
                    messageAlert("success", 'Bank Account details saved successfully');
                    dispatch(addUserInfo(response.data));
                  },
                  onError: (error) => {
                    var errorMessage = handleErrorMessage(error?.response);
                    messageAlert("error", errorMessage);
                  },
                }
            );
        }
    });

    return (
        <Grid container spacing={gridSpacing}>
            
                <Grid item xs={12}>
                    <RadioGroup aria-label="payment_channel" name="payment_channel" value={value1} onChange={handleChange1}>
                        <Grid container spacing={0}>
                            <Grid item>
                                <FormControlLabel value="momo" control={<Radio />} label="Mobile Money" />
                            </Grid>
                            <Grid item>
                                <FormControlLabel value="bank_account" control={<Radio />} label="Bank Account" />
                            </Grid>
                        </Grid>
                    </RadioGroup>
                </Grid>
                
                <Collapse in={value1 === 'momo'} sx={{ width: '100%' }}>
                    {value1 === 'momo' && (
                        <form onSubmit={formik.handleSubmit}>
                        <Grid item xs={12} sx={{ p: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <MuiPhoneNumber
                                        fullWidth
                                        margin="normal"
                                        defaultCountry={'gh'}
                                        variant="outlined"
                                        label="Mobile Money Number"
                                        disableAreaCodes
                                        name="momo_phone_number"
                                        required
                                        countryCodeEditable={false}
                                        onChange={e => formik.setFieldValue("momo_phone_number", e)}
                                        value={formik.values.momo_phone_number}
                                        error={formik.touched.momo_phone_number && Boolean(formik.errors.momo_phone_number)}
                                        helperText={formik.touched.momo_phone_number && formik.errors.momo_phone_number}
                                    />
                                </Grid>
                            
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-start">
                                        <AnimateButton>
                                            <LoadingButton
                                                disableElevation
                                                disabled={mutation.isLoading}
                                                startIcon={<MobileScreenShare />}
                                                type="submit"
                                                variant="outlined" size="large"
                                                loading={mutation.isLoading}
                                            > Save
                                            </LoadingButton>
                                            
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        </form>
                    )}
                </Collapse>
                <Collapse in={value1 === 'bank_account'} sx={{ width: '100%' }}>
                    {value1 === 'bank_account' && (
                        <form onSubmit={formikBankAccount.handleSubmit}>
                        <Grid item xs={12} sx={{ p: 3 }}>
                            <Grid container spacing={2}>
                                <Grid xs={12} container spacing={3}  sx={{ p: 3 }}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth
                                            required
                                            label="Name on account" 
                                            name="account_name"
                                            value={formikBankAccount.values.account_name}
                                            onChange={formikBankAccount.handleChange}
                                            error={formikBankAccount.touched.account_name && Boolean(formikBankAccount.errors.account_name)}
                                            helperText={formikBankAccount.touched.account_name && formikBankAccount.errors.account_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            required
                                            label="Bank Name" 
                                            name="bank_name"
                                            value={formikBankAccount.values.bank_name}
                                            onChange={formikBankAccount.handleChange}
                                            error={formikBankAccount.touched.bank_name && Boolean(formikBankAccount.errors.bank_name)}
                                            helperText={formikBankAccount.touched.bank_name && formikBankAccount.errors.bank_name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            required
                                            label="Account Number" 
                                            name="account_number"
                                            type={"number"}
                                            value={formikBankAccount.values.account_number}
                                            onChange={formikBankAccount.handleChange}
                                            error={formikBankAccount.touched.account_number && Boolean(formikBankAccount.errors.account_number)}
                                            helperText={formikBankAccount.touched.account_number && formikBankAccount.errors.account_number}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField 
                                            fullWidth 
                                            required
                                            label="Bank Branch" 
                                            name="bank_branch"
                                            value={formikBankAccount.values.bank_branch}
                                            onChange={formikBankAccount.handleChange}
                                            error={formikBankAccount.touched.bank_branch && Boolean(formikBankAccount.errors.bank_branch)}
                                            helperText={formikBankAccount.touched.bank_branch && formikBankAccount.errors.bank_branch}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="flex-start">
                                        <AnimateButton>
                                        <LoadingButton
                                                disableElevation
                                                disabled={mutationBank.isLoading}
                                                startIcon={<CreditCard />}
                                                type="submit"
                                                variant="outlined" size="large"
                                                loading={mutationBank.isLoading}
                                            > Save
                                            </LoadingButton>
    
                                        </AnimateButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                            
                        </Grid>
                        </form>
                    )}
                </Collapse>
            
        </Grid>
        
    );
};

export default Payment;
