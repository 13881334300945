// material-ui
import React from 'react';

import { CardActions, Divider, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Stack, TextField } from '@mui/material';

// project imports
import Avatar from 'ui-component/extended/Avatar';
import { gridSpacing } from 'store/constant';

// assets
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import constants from 'utils/constants';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useMutation } from 'react-query';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import UserProfile from 'service/UserProfile';
import { LoadingButton } from '@mui/lab';
import { addUserInfo } from 'store/user/userReducer';

// ==============================|| PROFILE 2 - USER PROFILE ||============================== //
const validationSchema = yup.object({
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    gender: yup.string().required('Gender is required'),
    year: yup.string().matches(/\b\d{4}\b/, {message: 'Must match YYYY'}).required('Year is required'),
    month: yup.string().required('Month is required'),
    day: yup.string().required('Day is required'),
    // id_type: yup.string().required('ID Type is required').nullable(),
    // id_number: yup.string().required('ID Number is required').nullable(),
    address: yup.string().required('Address is required').nullable(),
    // phone_number: yup.string().required('Address is required'),
    // email: yup.string().required('E-mail is required'),
    // email_notify: yup.bool().required('Email notification'),
    // sms_notify: yup.bool().required('SMS notification'),
});
const UserProfileSettings = () => {
    
    const user = useSelector((state) => state.user.userInfo);
    const dispatch = useDispatch();
    const mutation = useMutation(UserProfile.updateSelf);
    const formik = useFormik({
        initialValues: {...user, year:user?.date_of_birth?.split('-')[0], month:user?.date_of_birth?.split('-')[1], day:user?.date_of_birth?.split('-')[2]},
        validationSchema,
        onSubmit: (data) => {
            delete data['policies']
            data.date_of_birth = `${data?.year}-${data?.month}-${data?.day}`
            mutation.mutate(data,
                {
                  onSuccess: (response) => {
                    messageAlert("success", 'Saved successfully');
                    dispatch(addUserInfo(response.data));
                  },
                  onError: (error) => {
                    var errorMessage = handleErrorMessage(error?.response);
                    messageAlert("error", errorMessage);
                  },
                }
            );
        }
    });


    return (
        <form onSubmit={formik.handleSubmit}>
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar alt="User 1">{user?.first_name?.[0]}</Avatar>
                </Grid>
                <Grid item sm zeroMinWidth>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <input accept="image/*" style={{ display: 'none' }} id="contained-button-file" multiple type="file" />
                            </Stack>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography variant="caption">
                                <ErrorTwoToneIcon sx={{ height: 16, width: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                Image size Limit should be 125kb Max.
                            </Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField 
                fullWidth 
                label="First Name" 
                name="first_name"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField 
                fullWidth 
                label="Last Name" 
                name="last_name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
            />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Email Address" defaultValue={formik.values.email} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Phone Number" defaultValue={formik.values.phone_number} disabled/>
        </Grid>
        <Grid item xs={12} sm={6}>
            <RadioGroup row aria-label="gender" name="gender" value={formik.values.gender} onChange={formik.handleChange}>
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
            </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
            <FormLabel id="dob">Date of Birth</FormLabel>
            <Grid container spacing={1} sx={{mt: 1}}>
                <Grid item xs={4}>
                    <TextField
                        id="year"
                        name="year"
                        label="Year"
                        type={'number'}
                        value={formik.values.year}
                        onChange={formik.handleChange}
                        error={formik.touched.year && Boolean(formik.errors.year)}
                        helperText={formik.touched.year && formik.errors.year}
                        onInput = {(e) =>{
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="month"
                        select
                        fullWidth
                        label="Month"
                        name="month"
                        value={formik.values.month}
                        onChange={formik.handleChange}
                        error={formik.touched.month && Boolean(formik.errors.month)}
                        helperText={formik.touched.month && formik.errors.month}
                        >
                        {constants.MONTHS?.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id="day"
                        select
                        fullWidth
                        label="Day"
                        name="day"
                        value={formik.values.day}
                        onChange={formik.handleChange}
                        error={formik.touched.day && Boolean(formik.errors.day)}
                        helperText={formik.touched.day && formik.errors.day}
                        >
                        {constants.DAYS?.map((option, index) => (
                        <MenuItem key={index} value={option.value}>
                            {option.label}
                        </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            
            </Grid>
        </Grid>
        
        <Grid item xs={12} sm={6}>
            <TextField
                id="id_type"
                select
                fullWidth
                label="ID Type"
                name="id_type"
                value={formik.values.id_type}
                onChange={formik.handleChange}
                error={formik.touched.id_type && Boolean(formik.errors.id_type)}
                helperText={formik.touched.id_type && formik.errors.id_type}
                disabled
                >
                {constants.ID_TYPES?.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                    {option.label}
                </MenuItem>
                ))}
            </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextField 
                fullWidth 
                label="ID Number"
                name='id_number'
                value={formik.values.id_number}
                onChange={formik.handleChange}
                error={formik.touched.id_number && Boolean(formik.errors.id_number)}
                helperText={formik.touched.id_number && formik.errors.id_number}
                disabled
            />
        </Grid>
        <Grid item xs={12} sm={12}>
            <TextField 
                fullWidth 
                label="Address"
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
            />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
            <FormLabel id="sms_notify">SMS Notification</FormLabel>
            <RadioGroup row aria-label="sms_notify" name="sms_notify" value={formik.values.sms_notify} onChange={formik.handleChange}> 
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
            <FormLabel id="email_notify">Email Notification</FormLabel>
            <RadioGroup row aria-label="email_notify" name="email_notify" value={formik.values.email_notify} onChange={formik.handleChange}>
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
        </Grid> */}
    </Grid>
    <Divider sx={{mt: 5}}/>
    <CardActions>
        <Grid container justifyContent="space-between" spacing={0}>
            <Grid item></Grid>
            <Grid item>
                <AnimateButton>
                    <LoadingButton
                        disableElevation
                        disabled={mutation.isLoading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={mutation.isLoading}
                    >
                    
                        Save
                    </LoadingButton>
                </AnimateButton>
            </Grid>
        </Grid>
    </CardActions>
    </form>
    )
}

export default UserProfileSettings
