import React from 'react'
import { Navigate, useLocation, Outlet } from 'react-router-dom';

// project imports
// import Customization from '../Customization';

// ==============================|| MINIMAL LAYOUT ||============================== //



import { Link } from 'react-router-dom';

// material-ui
import { Grid } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import AuthCardWrapper from 'views/pages/authentication/AuthCardWrapper';
import AuthWrapper1 from 'views/pages/authentication/AuthWrapper1';
import { getLocalStorage } from 'utils/helpers/functions';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const MinimalLayout = () => {


    let pathname = useLocation();
    const accessToken = getLocalStorage("accessToken");


    if (accessToken) {
      return <Navigate to={{ 
        pathname: '/user/dashboard', 
        state: { from: pathname } 
      }} />;
    }


    console.log('path name',pathname?.pathname?.split('/')[1])
    let page = ['signup', 'invite'].includes(pathname?.pathname?.split('/')[1]) || pathname?.pathname?.includes('signup') ?'signup':'login'

    

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper page={page}>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="#">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                   <Outlet/>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

export default MinimalLayout;

