import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { CardContent, Grid, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { format } from 'date-fns';
// import ReactMarkdown from 'react-markdown';
// import gfm from 'remark-gfm';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';

import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';
import UserAccounts from 'service/UserAccounts';
import { useQuery } from 'react-query';



// ==============================|| MAIL DETAILS ||============================== //

const MailDetails = ({ handleUserDetails, data, }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));


    useQuery("notifications", UserAccounts.readNotification(data?.id));



    

  

    return (
        <MainCard
            sx={{
                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50]
            }}
            content={false}
        >
            <CardContent>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" justifyContent="space-between" spacing={matchDownSM ? 1 : 0}>
                            <Grid item>
                                <Stack direction="row" alignItems="center" spacing={matchDownSM ? 1 : 2}>
                                    <IconButton onClick={(e) => handleUserDetails(e, null)} size="small">
                                        <KeyboardArrowLeftTwoToneIcon />
                                    </IconButton>
                                    
                                    <Grid container alignItems="center">
                                        
                                        <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                                            <Typography variant="subtitle2">From: UBN</Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">{format(new Date(data?.created), 'd MMM')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
            <PerfectScrollbar style={{ height: 'calc(50vh)' }}>
                <CardContent sx={{ pt: 0 }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Grid container alignItems="center" spacing={0}>
                                        <Grid item>
                                            <Typography variant={matchDownSM ? 'h4' : 'h3'}>{data?.subject}</Typography>
                                        </Grid>
                                        <Grid item xs zeroMinWidth />
                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Dear {data?.user?.first_name},</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                '& > p': {
                                                    ...theme.typography.body1,
                                                    marginBottom: 0
                                                }
                                            }}
                                        >
                                            {data?.message}
                                            {/* <ReactMarkdown remarkPlugins={[gfm]}>{data?.message}</ReactMarkdown> */}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">Kind Regards,</Typography>
                                            <Typography variant="body2">UBN</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {data?.attachments && (
                                    <Grid item xs={12}>
                                        <Grid container spacing={gridSpacing}>
                                            <Grid item xs={12}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <AttachmentTwoToneIcon fontSize="small" />
                                                    <Typography variant="h5">{data?.attachments.length} </Typography>
                                                    <Typography variant="h5">Attachments</Typography>
                                                </Stack>
                                            </Grid>
                                            
                                        </Grid>
                                    </Grid>
                                )}
                                
                               
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </PerfectScrollbar>
        </MainCard>
    );
};

MailDetails.propTypes = {
    data: PropTypes.object,
    handleUserDetails: PropTypes.func,
    handleStarredChange: PropTypes.func,
    handleImportantChange: PropTypes.func
};

export default MailDetails;
