import PropTypes from 'prop-types';
import * as React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    ButtonBase,
    Checkbox,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Toolbar,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party

// project imports
import MailEmpty from './MailEmpty';
import MailListHeader from './MailListHeader';
import MainCard from 'ui-component/cards/MainCard';

// assets
import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';

import LabelTwoToneIcon from '@mui/icons-material/LabelTwoTone';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';

import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Visibility from '@mui/icons-material/Visibility';
import { cleanDate } from 'utils/helpers/functions';
import DeleteModal from './modals/DeleteModal';


// ==============================|| TABLE HEADER ||============================== //

function EnhancedTableHead({ selected }) {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="none" colSpan={5}>
                    <EnhancedTableToolbar numSelected={selected.length} />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    selected: PropTypes.array
};

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

const EnhancedTableToolbar = ({ numSelected }) => (
    <Toolbar
        sx={{
            p: 0,
            pl: 1,
            pr: 1,
            ...(numSelected > 0 && {
                color: (theme) => theme.palette.secondary.main
            })
        }}
    >
        {numSelected > 0 && (
            <Typography color="inherit" variant="h4" component="div">
                {numSelected} Mail Selected
            </Typography>
        )}
    </Toolbar>
);

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired
};

// ==============================|| CUSTOMER LIST ||============================== //

const MailList = ({ data, search, handleSearch, handleDrawerOpen, handleUserDetails, refetch}) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [deleteModal, setDeleteModal] = React.useState(false);
    const [message, setMessage] = React.useState({});

    function handleCloseModal(){
        setDeleteModal(false)
    }

    function handleDeleteModal(data){
        setMessage(data)
        setDeleteModal(true)
    }

    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

    const [denseTable, setDenseTable] = React.useState(false);
    const handleDenseTable = () => {
        setDenseTable(!denseTable);
    };

    const darkBG = '#ffff';


    return (
        <>
            <Grid container spacing={matchDownSM ? 3 : 1}>
                <Grid item xs={12}>
                    <MailListHeader
                        search={search}
                        handleSearch={handleSearch}
                        length={data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleDrawerOpen={handleDrawerOpen}
                        handleDenseTable={handleDenseTable}
                    />
                </Grid>
                <Grid item xs={12}>
                    {data?.length ? (
                        <MainCard content={false} sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50' }}>
                            {/* table */}
                            <TableContainer>
                                <Table
                                    size={denseTable ? 'small' : undefined}
                                    aria-labelledby="tableTitle"
                                    sx={{ minWidth: 320, '& td': { whiteSpace: 'nowrap', px: 0.75, py: denseTable ? 0.5 : 1.25 } }}
                                >
                                    <TableBody>
                                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    sx={{
                                                        bgcolor: !row?.read ? darkBG : '',
                                                        '& td:last-of-type>div': {
                                                            position: 'absolute',
                                                            top: '50%',
                                                            right: 5,
                                                            transform: 'translateY(-50%)',
                                                            display: 'none',
                                                            background: theme.palette.mode === 'dark' ? theme.palette.dark[800] : '#fff',
                                                            boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
                                                            borderRadius: '12px',
                                                            py: 1,
                                                            px: 1.75,
                                                            '& button + button': {
                                                                ml: 0.625
                                                            }
                                                        },
                                                        '&:hover': {
                                                            '& td:last-of-type>div': {
                                                                display: 'block'
                                                            }
                                                        }
                                                    }}
                                                    aria-checked={true}
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    <TableCell>
                                                        
                                                        <Checkbox
                                                            icon={<LabelOutlinedIcon />}
                                                            checkedIcon={<LabelTwoToneIcon />}
                                                            sx={{ '&.Mui-checked': { color: theme.palette.secondary.main } }}
                                                            checked={!row?.read}
                                                            
                                                            size="small"
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        id={labelId}
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={(e) => handleUserDetails(e, row)}
                                                    >
                                                        <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                                            
                                                            <Grid item xs zeroMinWidth>
                                                                <ButtonBase disableRipple>

                                                                    <Typography
                                                                        align="left"
                                                                        variant={row?.read ? 'body2' : 'subtitle1'}
                                                                        component="div"
                                                                    >
                                                                        {row?.subject}
                                                                    </Typography>
                                                                </ButtonBase>
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell sx={{ cursor: 'pointer' }} onClick={(e) => handleUserDetails(e, row)}>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                display: 'flex',
                                                                width: {
                                                                    xs: 220,
                                                                    md: 350,
                                                                    lg: 600,
                                                                    xl: 700
                                                                }
                                                            }}
                                                        >
                                                            <Typography
                                                                variant={row?.read ? 'body2' : 'subtitle1'}
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    display: 'block'
                                                                }}
                                                            >
                                                                {row.message}
                                                            </Typography>
                                                            
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.attach && (
                                                            <IconButton size="large">
                                                                <AttachmentTwoToneIcon fontSize="small" />
                                                            </IconButton>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ position: 'relative' }}>
                                                        {cleanDate(row?.created)}
                                                        <div>
                                                            
                                                            <IconButton size="large">
                                                                <DeleteTwoToneIcon fontSize="small" onClick={() => handleDeleteModal(row)}/>
                                                            </IconButton>
                                                            <IconButton size="large" onClick={(e) => handleUserDetails(e, row)}>
                                                                <Visibility fontSize="small" />
                                                            </IconButton>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: 53 * emptyRows
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </MainCard>
                    ) : (
                        <MailEmpty />
                    )}
                </Grid>
                <Grid item xs={12} sx={{ pt: '0 !important', display: { xs: 'block', sm: 'none' } }}>
                    {/* table pagination */}
                    <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={data?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Grid>
                <DeleteModal handleClose={handleCloseModal}  open={deleteModal} message={message} refetch={refetch}/>
            </Grid>
        </>
    );
};

MailList.propTypes = {
    data: PropTypes.array,
    handleDrawerOpen: PropTypes.func,
    handleUserDetails: PropTypes.func,
    handleStarredChange: PropTypes.func,
    handleImportantChange: PropTypes.func,
    handleSearch: PropTypes.func,
    search: PropTypes.string,
    refetch: PropTypes.func
};

export default MailList;
