import React from 'react'
// import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    // Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    useMediaQuery
} from '@mui/material';

// third party
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// assets

import { useMutation } from 'react-query';
import UserProfile from 'service/UserProfile';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { LoadingButton } from '@mui/lab';
import { strengthColor, strengthIndicatorNumFunc} from 'utils/helpers/password-strength';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'



// import Google from 'assets/images/icons/social-google.svg';

// ============================|| FIREBASE - LOGIN ||============================ //


const validationSchema = yup.object({
    password: yup.string().max(255).required('Password is required'),
    confirmPassword: yup.string().when('password', {
        is: (val) => !!(val && val.length > 0),
        then: yup.string().oneOf([yup.ref('password')], 'Both Password must match!')
    })
});

const ResetPassword = () => {
    const [searchParams] = useSearchParams();

    const theme = useTheme();
    let navigate = useNavigate()
    let { token } = useParams();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const { register, handleSubmit, formState: { errors },} = useForm({
        resolver: yupResolver(validationSchema),
      });


      const [showPassword, setShowPassword] = React.useState(false);
      const [strength, setStrength] = React.useState(0);
      const [level, setLevel] = React.useState();
    
      const handleClickShowPassword = () => {
          setShowPassword(!showPassword);
      };
  
      const handleMouseDownPassword = (event) => {
          event.preventDefault();
      };
  
      const changePassword = (value) => {
          const temp = strengthIndicatorNumFunc(value);
          setStrength(temp);
          setLevel(strengthColor(temp));
      };

    const refered_by = searchParams.get('rb') ? searchParams.get('rb') : null
    
    console.log('refered code', refered_by)
    

    const mutation = useMutation(UserProfile.resetPassword);
    const submit = (data) =>{
        data.token = token
        if(refered_by){
            data.refered_by = refered_by
        }
        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", "Reset email sent successfully");
                navigate('/auth/login')
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
          );
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center" textAlign="center" spacing={2}>
                    <Grid item xs={12}>
                        <Typography
                            color={theme.palette.secondary.main}
                            gutterBottom
                            variant={matchDownSM ? 'h3' : 'h2'}
                        >
                            Reset Password
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption" fontSize="16px" textAlign="center">
                            Enter new account password
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

    
            <Grid item xs={12}>
                    <form onSubmit={handleSubmit(submit)}>
                    <FormControl fullWidth error={Boolean(errors.password)} sx={{ ...theme.typography.customInput }}>
                        <InputLabel htmlFor="outlined-adornment-password-reset">Password</InputLabel>
                        <OutlinedInput
                            {...register("password")}
                            id="outlined-adornment-password-reset"
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            onChange={(e) => {
                                changePassword(e.target.value);
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps={{}}
                        />
                    </FormControl>
                    {errors.password && (
                        <FormControl fullWidth>
                            <FormHelperText error id="standard-weight-helper-text-reset">
                                {errors.password}
                            </FormHelperText>
                        </FormControl>
                    )}
                    {strength !== 0 && (
                        <FormControl fullWidth>
                            <Box sx={{ mb: 2 }}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <Box
                                            style={{ backgroundColor: level?.color }}
                                            sx={{
                                                width: 85,
                                                height: 8,
                                                borderRadius: '7px'
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="subtitle1" fontSize="0.75rem">
                                            {level?.label}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </FormControl>
                    )}
                    <FormControl
                        fullWidth
                        error={Boolean(errors?.confirmPassword)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            {...register("confirmPassword")}
                            id="outlined-adornment-confirm-password"
                            type={showPassword ? 'text' : 'password'}
                            name="confirmPassword"
                            label="Confirm Password"
                            inputProps={{}}
                        />
                    </FormControl>
                    {errors.confirmPassword && (
                        <FormControl fullWidth>
                            <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                {' '}
                                {errors.confirmPassword.message}{' '}
                            </FormHelperText>
                        </FormControl>
                    )}
                       


                        <Box sx={{ mt: 2 }}>
                            <AnimateButton>
                                
                                <LoadingButton
                                    disableElevation
                                    disabled={mutation.isLoading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={mutation.isLoading}
                                >
                                    Reset Password
                                </LoadingButton>
                            </AnimateButton>
                        </Box>
                    </form>
                    </Grid>

                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    
            
        </>
    );
};

export default ResetPassword;
