import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { formatDate } from 'utils/helpers/functions';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

export default function PaymentDetailsModal({handleClose, open, payment}) {
    const theme = useTheme();

   console.log('payment--->',payment)
   const affiliate  = payment?.payout_beneficiaries?.customer
   const affiliate_amount  = payment?.payout_beneficiaries?.amount
   const affiliate_amount_tax  = payment?.payout_beneficiaries?.amount_after_tax

   const over_ride_customer = payment?.payout_beneficiaries?.over_ride_customer
   const over_ride_amount = payment?.payout_beneficiaries?.over_ride_amount
   const over_ride_amount_tax = payment?.payout_beneficiaries?.over_ride_amount_after_tax
   


  
    
    return (
        <>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <>
                        <DialogTitle id="alert-dialog-title">Payment Details</DialogTitle>
                        <DialogContent sx={{width: '40rem'}}>
                            <DialogContentText id="alert-dialog-description">
                                <Grid container direction="column" spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs={12} sm={4} sx={{mb:3}}>
                                                <Typography variant="subtitle2">Date</Typography>
                                                <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{formatDate(payment?.created)}</Typography>
                                            </Grid>
                                            
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle2">Name</Typography>
                                                <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{payment?.customer?.first_name} {payment?.customer?.last_name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle2">Amount Paid</Typography>
                                                <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>GHC {payment?.amount}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle2">Duration</Typography>
                                                <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{payment?.duration}</Typography>
                                            </Grid> 
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle2">Range</Typography>
                                                <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{payment?.duration_range}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <Typography variant="subtitle2">Approved By</Typography>
                                                <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{payment?.approved_by?.first_name} {payment?.approved_by?.last_name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {affiliate && <>
                                        <Divider sx={{my:2}}/>

                                        <Typography variant="subtitle2">Affiliate Payout</Typography>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={4} sx={{mb:3}}>
                                                    <Typography variant="subtitle2">Name</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{affiliate?.first_name} {affiliate?.last_name}</Typography>
                                                </Grid>
                                                
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Gender</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{affiliate?.gender}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Phone Number</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{affiliate?.phone_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Amount Due</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>GHC {affiliate_amount}</Typography>
                                                </Grid> 
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Amount Due After Tax</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>GHC {affiliate_amount_tax}</Typography>
                                                </Grid> 
                                               
                                            </Grid>
                                        </Grid>
                                    </>}
                                    {over_ride_customer && <>
                                        <Divider sx={{my:2}}/>

                                        <Typography variant="subtitle2">Override Payout</Typography>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={12} sm={4} sx={{mb:3}}>
                                                    <Typography variant="subtitle2">Name</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{over_ride_customer?.first_name} {over_ride_customer?.last_name}</Typography>
                                                </Grid>
                                                
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Gender</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{over_ride_customer?.gender}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Phone Number</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>{over_ride_customer?.phone_number}</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Amount Due</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>GHC {over_ride_amount}</Typography>
                                                </Grid> 
                                                <Grid item xs={12} sm={4}>
                                                    <Typography variant="subtitle2">Amount Due After Tax</Typography>
                                                    <Typography variant="subtitle1 h6" sx={{color:'#616161'}}>GHC {over_ride_amount_tax}</Typography>
                                                </Grid> 
                                               
                                            </Grid>
                                        </Grid>
                                    </>}
                                </Grid>
                                
                                
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                onClick={handleClose}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
}
PaymentDetailsModal.propTypes = {
    handleClose: propTypes.func,
    open: propTypes.bool,
    payment: propTypes.object,
}