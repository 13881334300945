import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import propTypes from 'prop-types';
import UserAccounts from 'service/UserAccounts';
import { useMutation } from 'react-query';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { LoadingButton } from '@mui/lab';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

export default function DeleteModal({handleClose, open, message, refetch}) {
    const theme = useTheme();

    const mutation = useMutation(UserAccounts.deleteNotification)
    function handleDelete(){
        const data = {id: message?.id}
        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", "Message deleted successfully");
                refetch()
                handleClose()
              },
              onError: (error) => {
                handleErrorMessage(error?.response);
                messageAlert("error", "Message could not be deleted");
              },
            }
        );
    }
    
    return (
        <>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <>
                        <DialogTitle id="alert-dialog-title">Delete Message</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="body2" component="span">
                                    Are you sure you want to delete this message?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                onClick={handleClose}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <LoadingButton
                                autoFocus
                                disableElevation
                                disabled={mutation.isLoading}
                                size="small"
                                type="button"
                                variant="contained"
                                loading={mutation.isLoading}
                                onClick={() => handleDelete()}
                            >
                                Approve
                            </LoadingButton>
    
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
}
DeleteModal.propTypes = {
    handleClose: propTypes.func,
    open: propTypes.bool,
    message: propTypes.number,
    refetch: propTypes.func
}