import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, MenuItem, TextField, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { useMutation } from 'react-query';
import UserAccounts from 'service/UserAccounts';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import constants from 'utils/constants';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

export default function ApproveModal({handleClose, open, customer, refetch}) {
    const { register, handleSubmit, watch, formState: { errors },} = useForm({});
    const theme = useTheme();
    const mutation = useMutation(UserAccounts.approveCustomer)

    function submit(data){
        data.id = customer?.id
        data.category = customer?.approval
        data.duration_range = data?.duration_range ? data?.duration_range : new Date().getFullYear()
        mutation.mutate(data,
            {
              onSuccess: () => {
                messageAlert("success", 'Customer approved successfully');
                refetch()
                handleClose()
                
              },
              onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
              },
            }
        );
    }
    
    return (
        <>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <>
                        <DialogTitle id="alert-dialog-title" sx={{ textTransform: 'capitalize' }}>Approve Customer {customer?.approval}</DialogTitle>
                        <form onSubmit={handleSubmit(submit)}>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Typography variant="body2" component="span">
                                        Enter payment details
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                {...register('amount', {required: 'Amount is required'})}
                                                fullWidth
                                                label="Amount (GHC)"
                                                margin="normal"
                                                name="amount"
                                                type="number"
                                                required                                  
                                            />
                                            {errors?.amount && (
                                                <FormHelperText error>
                                                    {errors?.amount?.message || 'error this'}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                {...register('duration', {required: 'Duration is required'})}
                                                select
                                                fullWidth
                                                label="Duration"
                                                margin="normal"
                                                name="duration"
                                                type="text" 
                                                required
                                                error={errors?.duration?.message && Boolean(errors?.duration?.message)}
                                                helperText={errors?.duration?.message && errors?.duration?.message}
                                                >
                                                {constants.DURATION?.map((option, index) => (
                                                <MenuItem key={index} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                                ))}
                                            </TextField>
                                            
                                        </Grid>
                                        {watch('duration') !== 'annual' &&
                                            
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    {...register('duration_range', {required: 'Range is required'})}
                                                    select
                                                    fullWidth
                                                    label="Range"
                                                    margin="normal"
                                                    name="duration_range"
                                                    type="text" 
                                                    required
                                                    error={errors?.duration?.message && Boolean(errors?.duration?.message)}
                                                    helperText={errors?.duration?.message && errors?.duration?.message}
                                                    >
                                                    {constants.DURATION_RANGE[watch('duration')]?.map((option, index) => (
                                                    <MenuItem key={index} value={option.label}>
                                                        {option.label}
                                                    </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                        }
                                    </Grid>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ pr: 2.5 }}>
                                <Button
                                    sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                    onClick={handleClose}
                                    color="secondary"
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                        autoFocus
                                        disableElevation
                                        disabled={mutation.isLoading}
                                        size="small"
                                        type="sumbit"
                                        variant="contained"
                                        loading={mutation.isLoading}
                                        
                                    >
                                        Approve
                                    </LoadingButton>
                            </DialogActions>
                        </form>
                    </>
                )}
            </Dialog>
        </>
    );
}
ApproveModal.propTypes = {
    handleClose: propTypes.func,
    open: propTypes.bool,
    customer: propTypes.object,
    refetch: propTypes.func
}