import React from "react";
import { useEffect, useState } from "react";
import { getLocalStorage } from "../utils/helpers/functions";
import { Navigate, useLocation} from "react-router-dom";
import { useGetSelf, useRefreshToken } from "../utils/hooks/useUser";
import propTypes from 'prop-types'



export default function RequireAuth({ children }) {
    const [refresh, setRefresh] = useState(1);
    useEffect(() => {
      useRefreshToken();
    }, [refresh]);
    setTimeout(function () {
      setRefresh(refresh + 1);
    }, 240000);
  
    let location = useLocation();
    const accessToken = getLocalStorage("accessToken");
    useGetSelf()
    if (!accessToken) {
      return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
  }
  RequireAuth.propTypes = {
    children: propTypes.node,
  };
