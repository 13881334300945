import { useSelector } from 'react-redux';
import admin from './admin';
import client from './client';
import logout from './logout';

// ==============================|| MENU ITEMS ||============================== //
export default function menuItems(){
const user = useSelector((state) => state.user.userInfo);
const menu = user?.role === 'admin' ? admin : client

const menuItems = {
    items: [menu, logout]
};
return menuItems
}

