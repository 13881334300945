// third-party
import { createSlice } from '@reduxjs/toolkit';
import store from '../../store';


// project imports
import axios from 'axios';
import { getLocalStorage } from 'utils/helpers/functions';
import constants from 'utils/constants';

// ----------------------------------------------------------------------


const { dispatch } = store;

const initialState = {
    error: null,
    mails: [],
    unreadCount: undefined
};

const slice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET MAILS
        getMailsSuccess(state, action) {
            state.mails = action.payload;
            state.unreadCount = action.payload;
        },

        // FILTER MAILS
        filterMailsSuccess(state, action) {
            state.mails = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMails() {
    const BASE_API = constants.BASE_API

    return async () => {
        try {
            let config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            }
        }
            const response = await axios.get(`${BASE_API}/users/notifications`, config);
            dispatch(slice.actions.getMailsSuccess(response?.data?.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function filterMails(filter) {
    return async () => {
        try {
            const response = await axios.post('/api/mails/filter', { filter });
            dispatch(slice.actions.filterMailsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setImportant(id) {
    return async () => {
        try {
            await axios.post('/api/mails/setImportant', { id });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setStarred(id) {
    return async () => {
        try {
            await axios.post('/api/mails/setStarred', { id });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setRead(id) {
    return async () => {
        try {
            await axios.post('/api/mails/setRead', { id });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
