// material-ui
import React, { useState } from 'react'
// import { Typography } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { Badge, Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from '@mui/material';
import { useQuery } from 'react-query';
import UserAccounts from 'service/UserAccounts';
import { formatDate } from 'utils/helpers/functions';
import ApproveModal from './modals/ApproveRequest';


function PayoutRequests (){
    const [payment, setPayment] = useState({})


    function getStatusButton(value){
        if(value?.status === 'initialized'){
            return <Badge color={'primary'} badgeContent={'Initialized'} sx={{mx:4}}/>

        }else if(value?.status ==='rejected'){
            return <center><Badge color={'danger'} badgeContent={'Rejected'}/></center>
        }else{
            return <Button variant="outlined" size="small" onClick={()=>handleOpen(value)}>Approve</Button>
        }

    }
    
    

    const columns = [
        { id: 'created', label: 'Date', minWidth: 100, format: (value) => `${formatDate(value)}`},
        { id: 'customer', label: 'Customer', minWidth: 100, format: (value) => `${value.first_name} ${value.last_name}`},
        { id: 'amount', label: 'Amount', minWidth: 100,  format: (value) => (`GHS ${value}`)},
        { id: 'payment_channel', label: 'Payment Channel', minWidth: 100, format: (value) => value === 'momo'? 'Mobile Money':'Card'},
        { id: 'momo_number', label: 'MOMO Number', minWidth: 100, format: (value) => value?.customer?.momo_phone_number},
        // { id: 'approved', label: 'Approved By', minWidth: 100, format: (value) => value?.approved_by ? `${value?.approved_by?.first_name} ${value?.approved_by?.last_name}`:'N/A'},
        { id: 'data', label: '', minWidth: 100, format: (value) => getStatusButton(value)},
    ];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [open, setOpen] = useState(false);


    const {data: responseData, refetch} = useQuery("payout-requests", UserAccounts.payoutRequests);
    const payments = responseData?.data?.results;


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event?.target?.value);
        setPage(0);
    };

    const handleClose = () =>{
        setOpen(false)
    }

    const handleOpen = (value) =>{
        setOpen(true)
        setPayment(value)

    }


    
    return(
    
    <MainCard
    content={false}
    title="Payout Requests" 
    >

    {/* table */}
    <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell sx={{ py: 3 }} key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                            {column.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {payments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <TableRow sx={{ py: 3 }} hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                            const value = ['data', 'approved', 'momo_number'].includes(column.id) ? row : row[column.id];
                            return (
                                <TableCell key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    <ApproveModal handleClose={handleClose}  open={open} payment={payment} refetch={refetch}/>


    {/* table pagination */}
    <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={payments?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
    />


</MainCard>
)}

export default PayoutRequests;
