import * as React from 'react';
import propTypes from 'prop-types'

// material-ui
import {  Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';



export default function TermsAndConditions({signupData, setSignupData}) {
    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                Terms and Conditions
            </Typography>
            <Typography variant="subtitle2" gutterBottom sx={{ mb: 2 }}>
                <p>
                    UBN welcomes you as a new partner and referee of our products and services on the terms
                    and conditions of this agreement. We hope that your association with us will be successful
                    and rewarding.
                </p>
                <p>
                    By accepting you as a partner and referee, nothing whether expressed or implied, shall
                    be construed as creating a relationship of employer and employee or franchisor and
                    franchisee between you and the Distributorship.
                </p>
                <p>
                    As a partner, you are an independent contractor and as such, you will refer UBN products
                    for your own account. Specifically you are not controlled by UBN; your success is dependant
                    on the effort and hours that you put in. To improve communications between yourself and
                    UBN, we consent by sending you SMS’ and/or emails from time to time, for example
                    regarding promotions, presentation reminders and bonuses. The mobile phone number and
                    email address that you choose for purposes of these communications are those you have
                    filled in above. This agreement shall be governed and interpreted in accordance with the
                    laws of Ghana from time to time.
                </p>
                <p>
                    This agreement shall in no way be construed as an employment contract between UBN and
                    the Partners. The bonuses you generate comes from the referrals you give in accordance
                    with this agreement.
                </p>
                <p>
                    If you require any further information or assistance, please do not hesitate to contact your
                    upline who will happily assist you with your queries. If he/she cannot assist you please route
                    your request through him/her to the UBN Corporate Office.
                </p>
                <p>
                    By signing this agreement you confirm that you have read and understood the terms and
                    conditions of this agreement and you agree to all of the provisions contained in this
                    agreement.
                </p>
                
                <p>
                    
                </p>
            </Typography>
            <Grid item xs={12}>
                <FormControlLabel variant="subtitle2"
                    control={<Checkbox  name="terms_conditions" onClick={(e)=> setSignupData({...signupData, accept_terms:e.target.checked})}/>}
                    label="By ticking this agreement you confirm that you have read and understood the terms and conditions of this agreement and you agree to all of the provisions contained in this agreement."
                />
            </Grid>
           
        </>
    );
}

TermsAndConditions.propTypes = {
    signupData: propTypes.object,
    setSignupData: propTypes.func
}
