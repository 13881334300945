// material-ui
import React from 'react'
// import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/ubn_logo1.png';
import propTypes from 'prop-types';
 
// ==============================|| LOGO SVG ||============================== //

const Logo = ({width='150'}) => {
    // const theme = useTheme();

    return (
        <img src={logo} alt="Speedwave" width={width} />
        
       
    );
};

Logo.propTypes = {
    width:propTypes.string
}

export default Logo;
