import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorage } from "utils/helpers/functions";

const initialState = {
  userInfo: null
};

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
  },
});

export const { addUserInfo } = userReducer.actions;

export default userReducer.reducer;
