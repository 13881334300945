// assets
import { IconLogout } from '@tabler/icons';
import UserProfile from 'service/UserProfile';


const logout = {
    id: 'logout',
    type: 'group',
    children: [
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: '/auth/login',
            icon: IconLogout,
            breadcrumbs: false,
            logout: UserProfile.logout
        }
    ]
};

export default logout;
