import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import propTypes from 'prop-types';

// ===============================|| UI DIALOG - SWEET ALERT ||=============================== //

export default function SuspendModal({handleClose, open, user_id}) {
    const theme = useTheme();

    function handleApprove(){
        console.log('hello....',user_id)
    }
    
    return (
        <>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ p: 3 }}
            >
                {open && (
                    <>
                        <DialogTitle id="alert-dialog-title">Suspend Customer</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Typography variant="body2" component="span">
                                    Are you sure you want to suspend this customer?
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ pr: 2.5 }}>
                            <Button
                                sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                                onClick={handleClose}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" size="small" onClick={() => handleApprove()} autoFocus>
                                Suspend
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
}
SuspendModal.propTypes = {
    handleClose: propTypes.func,
    open: propTypes.bool,
    user_id: propTypes.number,
}