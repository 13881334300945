import React from 'react';

import PropTypes from 'prop-types';

// material-ui
import { Button, FormControlLabel, Grid, Stack, Typography, TextField, FormLabel, RadioGroup, Radio, FormHelperText, MenuItem} from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';
import MuiPhoneNumber from 'material-ui-phone-number';
import constants from 'utils/constants';




const validationSchema = yup.object({
    // title: yup.string().required('Title is required'),
    first_name: yup.string().required('First Name is required'),
    last_name: yup.string().required('Last Name is required'),
    gender: yup.string().required('Gender is required'),
    year: yup.string().matches(/\b\d{4}\b/, {message: 'Must match YYYY'}).required('Year is required'),
    month: yup.string().required('Month is required'),
    day: yup.string().required('Day is required'),
    id_type: yup.string().required('ID Type is required'),
    id_number: yup.string().required('ID Number is required'),
    address: yup.string().required('Postal address is required'),
    gps: yup.string().required('Residential address is required'),
    phone_number: yup.string().max('13').min('13').required('Phone number is required'),
    email: yup.string().required('E-mail is required'),
    // occupation: yup.string().required('Occupation is required'),
    // sum_assured: yup.string().required('Sum Assured is required'),
    place_of_birth: yup.string().required('Place of birth is required'),
});

// ==============================|| FORM WIZARD - VALIDATION  ||============================== //

const SignupPersonalDetails = ({ signupData, setSignupData, handleNext, setErrorIndex }) => {

    
    const formik = useFormik({
        initialValues: signupData,
        validationSchema,
        onSubmit: (values) => {
            setSignupData({...signupData, ...values})
            handleNext();
        }
    });



    

    
    
    return (
        <>
            <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                Personal Details
            </Typography>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormLabel id="title">Title </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="title"
                            name="title"
                            value={formik.values.title}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel value="mr" control={<Radio />} label="Mr" />
                            <FormControlLabel value="mrs" control={<Radio />} label="Mrs" />
                            <FormControlLabel value="miss" control={<Radio />} label="Miss" />
                        </RadioGroup>
                        {formik.touched.title && Boolean(formik.errors.title) && (
                            <FormHelperText error>
                                {formik.errors.title}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel id="title">Marital status *</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="marital_status"
                            name="marital_status"
                            value={formik.values.marital_status}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel value="single" control={<Radio />} label="Single" />
                            <FormControlLabel value="married" control={<Radio />} label="Married" />
                            <FormControlLabel value="divorced" control={<Radio />} label="Divorced" />
                            <FormControlLabel value="widowed" control={<Radio />} label="Widowed" />
                        </RadioGroup>
                        {formik.touched.marital_status && Boolean(formik.errors.marital_status) && (
                            <FormHelperText error>
                                {formik.errors.marital_status}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="first_name"
                            name="first_name"
                            label="First Name *"
                            value={formik.values.first_name}
                            onChange={formik.handleChange}
                            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                            helperText={formik.touched.first_name && formik.errors.first_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="last_name"
                            name="last_name"
                            label="Last Name *"
                            value={formik.values.last_name}
                            onChange={formik.handleChange}
                            error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                            helperText={formik.touched.last_name && formik.errors.last_name}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="other_names"
                            name="other_names"
                            label="Other name(s)"
                            value={formik.values.other_names}
                            onChange={formik.handleChange}
                            error={formik.touched.other_names && Boolean(formik.errors.other_names)}
                            helperText={formik.touched.other_names && formik.errors.other_names}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="nationality"
                            select
                            fullWidth
                            label="Country *"
                            name="nationality"
                            value={formik.values.nationality}
                            onChange={formik.handleChange}
                            error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                            helperText={formik.touched.nationality && formik.errors.nationality}
                            >
                            {constants.COUNTRIES?.map((option, index) => (
                            <MenuItem key={index} value={option.name}>
                                {option.name}
                            </MenuItem>
                            ))}
                        </TextField>
                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel id="gender">Gender *</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="gender"
                            name="gender"
                            value={formik.values.gender}
                            onChange={formik.handleChange}
                        >
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                        </RadioGroup>
                        {formik.touched.gender && Boolean(formik.errors.gender) && (
                            <FormHelperText error>
                                {formik.errors.gender}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormLabel id="dob">Date of Birth *</FormLabel>
                        <Grid container spacing={1} sx={{mt: 1}}>
                            <Grid item xs={4}>
                                <TextField
                                    id="year"
                                    name="year"
                                    label="Year"
                                    type={'number'}
                                    value={formik.values.year}
                                    onChange={formik.handleChange}
                                    error={formik.touched.year && Boolean(formik.errors.year)}
                                    helperText={formik.touched.year && formik.errors.year}
                                    onInput = {(e) =>{
                                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="month"
                                    select
                                    fullWidth
                                    label="Month"
                                    name="month"
                                    value={formik.values.month}
                                    onChange={formik.handleChange}
                                    error={formik.touched.month && Boolean(formik.errors.month)}
                                    helperText={formik.touched.month && formik.errors.month}
                                    >
                                    {constants.MONTHS?.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    id="day"
                                    select
                                    fullWidth
                                    label="Day"
                                    name="day"
                                    value={formik.values.day}
                                    onChange={formik.handleChange}
                                    error={formik.touched.day && Boolean(formik.errors.day)}
                                    helperText={formik.touched.day && formik.errors.day}
                                    >
                                    {constants.DAYS?.map((option, index) => (
                                    <MenuItem key={index} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                           id="id_type"
                           select
                           fullWidth
                           label="ID Type *"
                           name="id_type"
                           value={formik.values.id_type}
                           onChange={formik.handleChange}
                           error={formik.touched.id_type && Boolean(formik.errors.id_type)}
                           helperText={formik.touched.id_type && formik.errors.id_type}
                           >
                           {constants.ID_TYPES?.map((option, index) => (
                           <MenuItem key={index} value={option.value}>
                               {option.label}
                           </MenuItem>
                           ))}
                            
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="id_number" 
                            name="id_number" 
                            label="ID Number *"
                            value={formik.values.id_number}
                            onChange={formik.handleChange}
                            error={formik.touched.id_number && Boolean(formik.errors.id_number)}
                            helperText={formik.touched.id_number && formik.errors.id_number}
                            fullWidth />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                        <MuiPhoneNumber
                            margin="small"
                            defaultCountry={'gh'}
                            variant="outlined"
                            label="Phone Number *"
                            disableAreaCodes
                            name="phone_number"
                            countryCodeEditable={false}
                            value={formik.values.phone_number}
                            onChange={e => formik.setFieldValue("phone_number", e)}
                            error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                            helperText={formik.touched.phone_number && formik.errors.phone_number}
                            />
                    
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="email" 
                            name="email" 
                            type="email"
                            label="E-mail Address *"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="address" 
                            name="address"
                            label="Postal Address *" 
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={formik.touched.address && Boolean(formik.errors.address)}
                            helperText={formik.touched.address && formik.errors.address}
                            fullWidth 
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="gps" 
                            name="gps"
                            label="Residential address/ GPS *" 
                            value={formik.values.gps}
                            onChange={formik.handleChange}
                            error={formik.touched.gps && Boolean(formik.errors.gps)}
                            helperText={formik.touched.gps && formik.errors.gps}
                            fullWidth 
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="home_town" 
                            name="home_town"
                            label="Home town *" 
                            value={formik.values.home_town}
                            onChange={formik.handleChange}
                            error={formik.touched.home_town && Boolean(formik.errors.home_town)}
                            helperText={formik.touched.home_town && formik.errors.home_town}
                            fullWidth 
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="place_of_birth" 
                            name="place_of_birth"
                            label="Place of birth *" 
                            value={formik.values.place_of_birth}
                            onChange={formik.handleChange}
                            error={formik.touched.place_of_birth && Boolean(formik.errors.place_of_birth)}
                            helperText={formik.touched.place_of_birth && formik.errors.place_of_birth}
                            fullWidth 
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="occupation" 
                            name="occupation"
                            label="Occupation " 
                            value={formik.values.occupation}
                            onChange={formik.handleChange}
                            error={formik.touched.occupation && Boolean(formik.errors.occupation)}
                            helperText={formik.touched.occupation && formik.errors.occupation}
                            fullWidth 
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="sum_assured" 
                            name="sum_assured"
                            label="Sum assured *" 
                            value={formik.values.sum_assured}
                            onChange={formik.handleChange}
                            error={formik.touched.sum_assured && Boolean(formik.errors.sum_assured)}
                            helperText={formik.touched.sum_assured && formik.errors.sum_assured}
                            fullWidth 
                            />
                    </Grid>
                    {/* {formik.values.marital_status === 'married' && <>
                    <Grid item xs={12} sm={6}>
                        <TextField 
                            id="spouse_name" 
                            name="spouse_name"
                            label="Spouse name *" 
                            value={formik.values.spouse_name}
                            onChange={e => formik.setFieldValue("spouse_name", e.target.value)}
                            error={formik.touched.spouse_name && Boolean(formik.errors.spouse_name)}
                            helperText={formik.touched.spouse_name && formik.errors.spouse_name}
                            fullWidth 
                            />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPhoneNumber
                            margin="small"
                            defaultCountry={'gh'}
                            variant="outlined"
                            label="Phone number *"
                            disableAreaCodes
                            name="spouse_phone_number"
                            countryCodeEditable={false}
                            value={formik.values.spouse_phone_number}
                            onChange={e => formik.setFieldValue("spouse_phone_number", e)}
                            error={formik.touched.spouse_phone_number && Boolean(formik.errors.spouse_number)}
                            helperText={formik.touched.spouse_phone_number && formik.errors.spouse_phone_number}
                            />
                    </Grid>
                    </>
                    } */}
                                   
                    <Grid item xs={12}>
                        <Stack direction="row" justifyContent="flex-end">
                            <AnimateButton>
                                <Button variant="contained" sx={{ my: 0, ml: 0 }} type="submit" onClick={() => setErrorIndex(0)}>
                                    Next
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

SignupPersonalDetails.propTypes = {
    signupData: PropTypes.object,
    setSignupData: PropTypes.func,
    handleNext: PropTypes.func,
    setErrorIndex: PropTypes.func
};

export default SignupPersonalDetails;
