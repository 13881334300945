// material-ui
import React from 'react';

import { Box, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { strengthColor, strengthIndicatorNumFunc } from 'utils/helpers/password-strength';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import UserProfile from 'service/UserProfile';
import { useMutation } from 'react-query';
import { handleErrorMessage, messageAlert } from 'utils/helpers/functions';
import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';


// ==============================|| PROFILE 2 - CHANGE PASSWORD ||============================== //


const validationSchema = yup.object({
    old_password: yup.string().max(255).required('Current password is required'),
    confirmPassword: yup.string().when('new_password', {
        is: (val) => !!(val && val.length > 0),
        then: yup.string().oneOf([yup.ref('new_password')], 'Both Password must match!')
    })
});
const ChangePassword = () => {
    const theme = useTheme();

    const { register, handleSubmit, formState: { errors },} = useForm({
        resolver: yupResolver(validationSchema),
      });

    const [showPassword, setShowPassword] = React.useState(false);
      const [strength, setStrength] = React.useState(0);
      const [level, setLevel] = React.useState();
    
      const handleClickShowPassword = () => {
          setShowPassword(!showPassword);
      };
  
      const handleMouseDownPassword = (event) => {
          event.preventDefault();
      };
  
      const changePassword = (value) => {
          const temp = strengthIndicatorNumFunc(value);
          setStrength(temp);
          setLevel(strengthColor(temp));
      };



    const mutation = useMutation(UserProfile.changePassword);
    const submit = (data) =>{
        mutation.mutate(data,
            {
            onSuccess: () => {
                messageAlert("success", "Password changed successfully");
                document.getElementById("password-form").reset()
            },
            onError: (error) => {
                var errorMessage = handleErrorMessage(error?.response);
                messageAlert("error", errorMessage);
            },
            }
        );
    }

    return(
        <form onSubmit={handleSubmit(submit)} id="password-form">
    <Grid container spacing={gridSpacing}>
        
        <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={Boolean(errors.old_password)} sx={{ ...theme.typography.customInput }}>
                <InputLabel htmlFor="outlined-adornment-password-reset">Current Password</InputLabel>
                <OutlinedInput
                    fullWidth 
                    {...register("old_password")}
                    placeholder="**********"
                    id="outlined-adornment-password-reset"
                    type={showPassword ? 'text' : 'password'}
                    name="old_password"
                    onChange={(e) => {
                        changePassword(e.target.value);
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="large"
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }
                    />
            </FormControl>
            {errors.old_password && (
                        <FormControl fullWidth>
                            <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                {' '}
                                {errors.old_password.message}{' '}
                            </FormHelperText>
                        </FormControl>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
            
        </Grid>
        <Grid item xs={12} sm={6}>
            <FormControl
                fullWidth
                error={Boolean(errors?.confirmPassword)}
                sx={{ ...theme.typography.customInput }}
                >
                        <InputLabel htmlFor="outlined-adornment-confirm-password">New Password</InputLabel>
                        <OutlinedInput
                            {...register("new_password")}
                            id="outlined-adornment-confirm-password"
                            type={showPassword ? 'text' : 'password'}
                            name="new_password"
                            label="New Password"
                            placeholder="**********"
                            inputProps={{}}
                        />
                    </FormControl>
                    {errors.confirmPassword && (
                        <FormControl fullWidth>
                            <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                {' '}
                                {errors.confirmPassword.message}{' '}
                            </FormHelperText>
                        </FormControl>
                    )}
        </Grid>
        <Grid item xs={12} sm={6}>
            <FormControl
                fullWidth
                error={Boolean(errors?.confirmPassword)}
                sx={{ ...theme.typography.customInput }}
                >
                <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                <OutlinedInput
                    {...register("confirmPassword")}
                    id="outlined-adornment-confirm-password"
                    type={showPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="**********"
                    inputProps={{}}
                />
            </FormControl>
            {errors.confirmPassword && (
                <FormControl fullWidth>
                    <FormHelperText error id="standard-weight-helper-text-confirm-password">
                        {' '}
                        {errors.confirmPassword.message}{' '}
                    </FormHelperText>
                </FormControl>
            )}
        </Grid>
        <Grid item xs={12} sm={6}>
       
        {strength !== 0 && (
            <FormControl fullWidth>
                <Box sx={{ mb: 2 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Box
                                style={{ backgroundColor: level?.color }}
                                sx={{
                                    width: 85,
                                    height: 8,
                                    borderRadius: '7px'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1" fontSize="0.75rem">
                                {level?.label}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </FormControl>
        )}
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid item xs={12} sm={6}>
            <Stack direction="row">
                <AnimateButton>
                    <LoadingButton
                        disableElevation
                        disabled={mutation.isLoading}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        loading={mutation.isLoading}
                    >
                        Change Password
                    </LoadingButton>
                </AnimateButton>
            </Stack>
        </Grid>
        
    </Grid>
    </form>
)};

export default ChangePassword;
